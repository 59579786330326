import Stack from '@mui/material/Stack';
import RecentApplicationCard from './application-card/RecentApplicationCard';
import { useTranslation } from 'react-i18next';
import useRecentApplications from './application-card/useRecentApplications';
import EmptyContentCard from 'components/empty-content-card/EmptyContentCard';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { Skeleton } from '@mui/material';
import { useContext } from 'react';
import { HomeUserApplicationContext } from 'contexts/HomeUserApplicationContext';
import { useNavigate } from 'react-router-dom';

const RecentApplicationList = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const { recentApplications, loading } = useRecentApplications();
    const { hasApplications } = useContext(HomeUserApplicationContext);

    if (loading) {
        return <Skeleton sx={{ height: '170px' }} />;
    }

    const goToAllAppsPage = () => {
        let path = `/all-applications`;
        navigate(path);
    };

    if (recentApplications.length === 0) {
        return (
            <EmptyContentCard
                title={t('HOME.RECENT_APPLICATIONS_LIST.NO_RECENTS.TITLE')}
                subtitle={
                    hasApplications
                        ? t('HOME.RECENT_APPLICATIONS_LIST.NO_RECENTS.SUBTITLE')
                        : t('HOME.RECENT_APPLICATIONS_LIST.NO_APPS.SUBTITLE')
                }
                hasAction={hasApplications}
                actionButtonText={t('HOME.RECENT_APPLICATIONS_LIST.NO_RECENTS.BUTTON_CTA')}
                actionButtonIconBefore={<WidgetsOutlinedIcon />}
                actionButtonOnClick={() => goToAllAppsPage()}
            />
        );
    }

    return (
        <Stack direction='row' gap={2} flexWrap='wrap'>
            {recentApplications.length > 0 &&
                recentApplications.map((app) => (
                    <RecentApplicationCard application={app} key={app.name} />
                ))}
        </Stack>
    );
};

export default RecentApplicationList;
