import { Box, Stack, Typography } from '@mui/material';
import { ShortcutFolderModel } from 'models/ShortcutFolderModel';
import ShortcutFolderCard from 'components/shortcut-list-v2/shortcut-dialog/shortcut-folder-card/ShortcutFolderCard';
import ShortcutCard from 'components/shortcut-list-v2/shortcut-dialog/shortcut-card/ShortcutCard';
import { AppIcon } from '../../../app-icon/AppIcon';
import { ApplicationModel } from '../../../../models/ApplicationModel';

interface ShortcutFolderHeaderProps {
    shortcutFolder: ShortcutFolderModel;
    application?: ApplicationModel;
    onClick: (folderName: string) => void;
}

const ShortcutFolderHeader = (props: ShortcutFolderHeaderProps) => {
    const { application, shortcutFolder, onClick } = props;

    return (
        <Stack sx={{ flexGrow: 1, width: '100%' }} gap={1}>
            <Stack flexDirection='row' alignItems='center'>
                {shortcutFolder.applicationName && (
                    <Box
                        sx={{
                            marginRight: '10px',
                        }}
                    >
                        <AppIcon src={application?.imageUrl} appName={application?.name} />
                    </Box>
                )}
                <Typography
                    fontWeight={'bold'}
                    fontSize='18px'
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                    }}
                >
                    {shortcutFolder.displayName}
                </Typography>
            </Stack>
            <Stack sx={{ flexGrow: 1, width: '100%', overflowY: 'auto' }} gap={2}>
                {shortcutFolder.subfolders.map((sf) => (
                    <ShortcutFolderCard shortcutFolder={sf} onClick={onClick} key={sf.name} />
                ))}
                {shortcutFolder.shortcuts.map((shortcut) => (
                    <ShortcutCard shortcut={shortcut} key={shortcut.name} />
                ))}
            </Stack>
        </Stack>
    );
};

export default ShortcutFolderHeader;
