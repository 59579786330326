import { Alert, Button, Snackbar, Stack, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import useApplicationLink from 'hooks/useApplicationLink';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMobileView from 'hooks/useMobileView';
import { Link } from 'react-router-dom';
import { ShortcutV2Model } from 'models/ShortcutV2Model';

interface ShortcutCardProps {
    shortcut: ShortcutV2Model;
}

export const ShortcutLinkContainer = ({
    shortcut,
    children,
}: {
    shortcut: ShortcutV2Model;
    children: ReactNode;
}) => {
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
    };

    return shortcut.url ? (
        <Link to={shortcut.url} onClick={handleLinkClick}>
            {children}
        </Link>
    ) : (
        <>{children}</>
    );
};

const ShortcutCard = (props: ShortcutCardProps) => {
    const { shortcut } = props;
    const { t } = useTranslation();
    const [warningOpen, setWarningOpen] = useState(false);
    const isMobileView = useMobileView();
    const linkOut = useApplicationLink(shortcut.url);

    const handleClick = (shortcut: ShortcutV2Model) => () => {
        if (isMobileView && !shortcut.mobileFriendly) {
            setWarningOpen(true);
        } else if (shortcut.url) {
            window.open(linkOut(), '_blank');
        }
    };

    return (
        <ShortcutLinkContainer shortcut={shortcut}>
            <Button
                key={shortcut.name}
                sx={{
                    color: (theme) => theme.palette.text.primary,
                    textTransform: 'none',
                    p: 2,
                    borderRadius: '8px',
                    backgroundColor: (theme) => theme.palette.background.default,
                    borderColor: (theme) => theme.palette.divider,
                    ':hover': {
                        backgroundColor: (theme) => theme.palette.action.hover,
                        cursor: 'pointer',
                    },
                    width: '100%',
                }}
                onClick={handleClick(shortcut)}
                data-element-id={shortcut.url}
                variant='outlined'
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{ width: '100%', textAlign: 'left' }}
                >
                    <Stack>
                        <Typography fontWeight='bold' fontSize='16px'>
                            {shortcut.displayName}
                        </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                color: (theme) => theme.palette.text.secondary,
                            }}
                        >
                            {shortcut.description}
                        </Typography>
                    </Stack>
                    {shortcut.url && (
                        <LaunchIcon
                            fontSize='small'
                            sx={{
                                color: (theme) => theme.palette.action.active,
                            }}
                        />
                    )}
                </Stack>
            </Button>

            <Snackbar
                open={warningOpen}
                autoHideDuration={3000}
                onClose={() => setWarningOpen(false)}
            >
                <Alert severity='warning' sx={{ width: '100%' }}>
                    {t('HOME.BUCKETS.MOBILE_UNFRIENDLY')}
                </Alert>
            </Snackbar>
        </ShortcutLinkContainer>
    );
};

export default ShortcutCard;
