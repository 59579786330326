import { Box, Theme } from '@mui/material';
import { ReactNode } from 'react';

export const SwipeableDrawerHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Box height='auto' width='100%'>
            <Box
                sx={(theme: Theme) => ({
                    margin: '10px auto',
                    width: '36px',
                    height: '5px',
                    backgroundColor: theme.palette.action.active,
                    borderRadius: '33px',
                })}
            />
            <Box
                sx={{
                    paddingX: '16px',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
