const addQueryParams = (url: string, key: string, value: string): string => {
    if (!url || !key || !value) {
        return '';
    }

    return `${url}${url.includes('?') ? '&' : '?'}${key}=${value}`;
};

const uriQueryUtils = {
    addQueryParams,
};

export default uriQueryUtils;
