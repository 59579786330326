const truncateText = (text: string, limit: number) => {
    if (text.length <= limit) return text;
    const truncated = text.substring(0, limit);
    return `${truncated.substring(0, truncated.lastIndexOf(' '))}...`;
};

const textUtils = {
    truncateText,
};

export default textUtils;
