import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';
import { Fade, Slide } from '@mui/material';

const SlideTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const FadeTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

export const ModalTransitions = {
    SlideTransition,
    FadeTransition,
};
