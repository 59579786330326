import { ForwardedRef, forwardRef, PropsWithChildren, ReactNode } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SwipeableDrawer,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ModalTransitions } from 'components/Transitions';
import CloseIcon from '@mui/icons-material/Close';
import useMobileView from 'hooks/useMobileView';
import { SwipeableDrawerHeader } from './SwipeableDrawerHeader';

interface ModalDrawerContainerProps extends PropsWithChildren {
    open: boolean;
    onOpen?: () => void;
    onClose: () => void;
    HeaderComponent: ReactNode;
    DividerComponent?: ReactNode;
    FooterComponent?: ReactNode;
}

const ResponsiveDrawer = forwardRef(
    (
        {
            open,
            onOpen,
            onClose,
            HeaderComponent,
            DividerComponent,
            FooterComponent,
            children,
        }: ModalDrawerContainerProps,
        ref: ForwardedRef<any>,
    ) => {
        return (
            <SwipeableDrawer
                ref={ref}
                disableDiscovery
                disableSwipeToOpen
                anchor='bottom'
                open={open}
                onClose={onClose}
                onOpen={onOpen ? onOpen : () => {}}
                PaperProps={{
                    sx: {
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                        height: '90vh',
                        overflowY: 'hidden',
                    },
                }}
            >
                <Stack gap={2} sx={{ height: '100%', paddingTop: 2 }}>
                    <SwipeableDrawerHeader>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            {HeaderComponent}
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Box>
                    </SwipeableDrawerHeader>
                    {DividerComponent ? DividerComponent : <Divider />}
                    <Box
                        px={2}
                        sx={{
                            height: '100%',
                            overflowY: 'auto',
                        }}
                    >
                        {children}
                    </Box>
                    {FooterComponent}
                </Stack>
            </SwipeableDrawer>
        );
    },
);

ResponsiveDrawer.displayName = 'Responsive Drawer';

const ResponsiveModal = forwardRef(
    (
        {
            open,
            onClose,
            HeaderComponent,
            DividerComponent,
            FooterComponent,
            children,
        }: Omit<ModalDrawerContainerProps, 'onOpen'>,
        ref: ForwardedRef<any>,
    ) => {
        return (
            <Dialog
                ref={ref}
                open={open}
                onClose={onClose}
                maxWidth='tablet_lg'
                fullWidth
                TransitionComponent={ModalTransitions.FadeTransition}
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        padding: '24px',
                    }}
                >
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        {HeaderComponent}
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                {DividerComponent ? DividerComponent : <Divider />}
                <DialogContent sx={{ maxHeight: '500px', padding: '24px' }}>
                    <div>{children}</div>
                </DialogContent>
                {FooterComponent}
            </Dialog>
        );
    },
);

ResponsiveModal.displayName = 'Responsive Modal';

const ModalDrawerContainer = forwardRef((props: ModalDrawerContainerProps, ref) => {
    const isMobileView = useMobileView();

    return isMobileView ? (
        <ResponsiveDrawer ref={ref} {...props}>
            {props.children}
        </ResponsiveDrawer>
    ) : (
        <ResponsiveModal ref={ref} {...props}>
            {props.children}
        </ResponsiveModal>
    );
});

ModalDrawerContainer.displayName = 'Modal Drawer Container';

export default ModalDrawerContainer;
