import { FC } from 'react';
import ModalDrawerContainer from 'components/modal-drawer-container/ModalDrawerContainer';
import { Box, Stack, Typography } from '@mui/material';
import { ApplicationModel } from 'models/ApplicationModel';
import { AppIcon } from 'components/app-icon/AppIcon';

interface ApplicationDetailDialogProps {
    application: ApplicationModel;
    open: boolean;
    onClose: () => void;
}

const ApplicationDetailDialog: FC<ApplicationDetailDialogProps> = ({
    application,
    open,
    onClose,
}) => {
    return (
        <ModalDrawerContainer
            open={open}
            onClose={onClose}
            onOpen={() => {}}
            HeaderComponent={
                <Stack direction='row' gap={2} sx={{ alignItems: 'center' }}>
                    <AppIcon
                        src={application.imageUrl}
                        appName={application.displayName || application.name}
                        size='large'
                    />
                    <Stack>
                        <Typography variant='h3' fontWeight='600' fontSize='20px'>
                            {application.displayName}
                        </Typography>
                    </Stack>
                </Stack>
            }
        >
            {application.shortDescription ? (
                <Stack
                    gap={2}
                    sx={{
                        height: '100%',
                        minHeight: '400px',
                        overflowX: 'hidden',
                    }}
                    alignItems='flex-start'
                >
                    <Typography variant='h5' fontWeight='600' fontSize='16px'>
                        Description
                    </Typography>
                    <Typography
                        variant='body1'
                        fontWeight='400'
                        fontSize='16px'
                        sx={{
                            color: (theme) => theme.palette.text.secondary,
                        }}
                    >
                        {application.shortDescription}
                    </Typography>
                </Stack>
            ) : (
                <Box
                    sx={{
                        height: '100%',
                        minHeight: '400px',
                        overflowX: 'hidden',
                    }}
                />
            )}
        </ModalDrawerContainer>
    );
};

export default ApplicationDetailDialog;
