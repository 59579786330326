import { Stack, Typography } from '@mui/material';
import AppLink from 'components/app-link/AppLink';
import { Trans, useTranslation } from 'react-i18next';
import UseMobileView from '../../hooks/useMobileView';

const getTermsOfServiceUrl = (): string => {
    return `${window._env_.IDENTITY_URL}/terms-and-conditions`;
};

const AppFooter = () => {
    const year = new Date().getFullYear();
    const { t } = useTranslation();
    const isMobile = UseMobileView();

    return (
        <Stack
            direction='row'
            gap={1}
            sx={{
                height: 'var(--app-footer-height)',
                px: 'var(--app-padding-side)',
                py: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: (theme) => theme.palette.background.paper,
                width: `calc(100% - 'var(--app-footer-sidebar-margin)')`,
                ml: { laptop: 'var(--app-footer-sidebar-margin)' },
            }}
        >
            <Typography variant='body2'>
                &copy; <Trans i18nKey={'FOOTER.COPYRIGHT'} values={{ year: year }} />
            </Typography>
            <Stack
                sx={{
                    textAlign: 'right',
                    gap: isMobile ? 0.5 : 2,
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <AppLink href='https://www.ncr.com/privacy' label={t('FOOTER.PRIVACY')} />
                <AppLink href={getTermsOfServiceUrl()} label={t('FOOTER.TERMS_OF_SERVICE')} />
                <AppLink href='https://www.ncr.com/legal-notice' label={t('FOOTER.LEGAL')} />
            </Stack>
        </Stack>
    );
};

export default AppFooter;
