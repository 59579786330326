import uriQueryUtils from 'utils/uri-query.utils';
import {
    QueryParamKeys,
    useOrganizationContext,
} from '@ncr-voyix-commerce/react-common-components';
import { useCallback } from 'react';

const useApplicationLink = (applicationUrl: string) => {
    const { organization } = useOrganizationContext();

    const linkOut = useCallback(() => {
        return (
            uriQueryUtils.addQueryParams(
                applicationUrl,
                QueryParamKeys.organization,
                organization?.organizationName || '',
            ) || applicationUrl
        );
    }, [organization]);

    return linkOut;
};

export default useApplicationLink;
