import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/config';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { getLaunchDarklySdkKey } from './constants/frontend-config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: getLaunchDarklySdkKey(),
        context: {
            kind: 'user',
            key: 'default',
        },
    });

    root.render(
        <LDProvider>
            <React.StrictMode>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.StrictMode>
        </LDProvider>,
    );
})();
