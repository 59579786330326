import { Typography, Stack, Button, Box, Slide, Alert, Skeleton } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShortcutIcon } from 'components/shortcut-list/ShortcutIcon';
import ModalDrawerContainer from 'components/modal-drawer-container/ModalDrawerContainer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import shortcutService from 'services/shorcuts.service';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import { ShortcutFolderModel } from 'models/ShortcutFolderModel';
import ShortcutFolderCard from './shortcut-folder-card/ShortcutFolderCard';
import { ShortcutGroupModel } from 'models/ShortcutGroupModel';
import { ShortcutV2Model } from 'models/ShortcutV2Model';
import ShortcutCard from './shortcut-card/ShortcutCard';
import ShortcutFolderHeader from './shortcut-folder-header/ShortcutFolderHeader';
import { HomeUserApplicationContext } from '../../../contexts/HomeUserApplicationContext';

interface ShortcutGroupDialogProps {
    open: boolean;
    onClose: () => void;
    shortcutGroup: ShortcutGroupModel;
}

interface ShortcutGroupHistory {
    shortcutFolders: ShortcutFolderModel[];
    shortcuts: ShortcutV2Model[];
}

const ShortcutGroupDialog = ({ open, onClose, shortcutGroup }: ShortcutGroupDialogProps) => {
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [shortcutFolders, setShortcutFolders] = useState<Array<ShortcutFolderModel>>([]);
    const [shortcuts, setShortcuts] = useState<Array<ShortcutV2Model>>([]);
    const [history, setHistory] = useState<Array<ShortcutGroupHistory>>([]);
    const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('left');
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const { organization } = useOrganizationContext();
    const { userApplications } = useContext(HomeUserApplicationContext);

    useEffect(() => {
        if (open && organization?.organizationName) {
            setLoading(true);
            shortcutService
                .getShortcutGroupByName(organization?.organizationName, shortcutGroup.name)
                .then((response) => {
                    setShortcutFolders(response.data.shortcutFolders);
                    setShortcuts(response.data.shortcuts);
                })
                .finally(() => setLoading(false));
        } else {
            setShortcutFolders([]);
            setShortcuts([]);
            setHistory([]);
        }
    }, [open, organization?.organizationName, shortcutGroup.name]);

    function updateBucketList(folderName: string) {
        if (!organization?.organizationName) {
            return;
        }

        setDataLoaded(false);
        setLoading(true);
        setShortcutFolders([]);
        setShortcuts([]);
        setSlideDirection('left');

        shortcutService
            .getShortcutFolderByName(organization?.organizationName, folderName)
            .then((response) => {
                setHistory((prev) => {
                    return [...prev, { shortcutFolders, shortcuts }];
                });

                setShortcutFolders(response.data.subfolders);
                setShortcuts(response.data.shortcuts);
                setDataLoaded(true);
            });

        setTimeout(() => {
            setLoading(false);
        }, 25);
    }

    const backButtonClick = () => {
        if (!history.length) {
            return;
        }

        const previous = history[history.length - 1];
        setLoading(true);
        setSlideDirection('right');

        setShortcutFolders(previous?.shortcutFolders || []);
        setShortcuts(previous?.shortcuts || []);

        setHistory((prev) => {
            return prev.slice(0, prev.length - 1);
        });

        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const hasBackButton = () => {
        return history.length > 0;
    };

    return (
        <ModalDrawerContainer
            ref={containerRef}
            open={open}
            onClose={onClose}
            onOpen={() => {}}
            HeaderComponent={
                <Stack direction='row' gap={2} sx={{ alignItems: 'center' }}>
                    <ShortcutIcon icon={shortcutGroup.icon} />
                    <Typography variant='h5' fontWeight='600' fontSize='16px'>
                        {shortcutGroup.displayName}
                    </Typography>
                </Stack>
            }
            FooterComponent={
                hasBackButton() && (
                    <Box height='84px'>
                        <Button onClick={backButtonClick} sx={{ m: '12px 24px' }}>
                            <ArrowBackIcon
                                fontSize='small'
                                sx={{
                                    marginRight: '8px',
                                    fontSize: '15px',
                                }}
                            />
                            {t('COMMON.BACK')}
                        </Button>
                    </Box>
                )
            }
        >
            <Stack
                gap={4}
                sx={{
                    height: '100%',
                    minHeight: '400px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}
                alignItems='flex-start'
            >
                {shortcutGroup.prompt && (
                    <Typography variant='h1' fontWeight='700' fontSize='25px'>
                        {shortcutGroup.prompt}
                    </Typography>
                )}
                {dataLoaded && shortcutFolders.length === 0 && shortcuts.length === 0 && (
                    <Slide
                        timeout={500}
                        direction='left'
                        in={shortcutFolders.length === 0 && shortcuts.length === 0}
                        container={containerRef.current}
                    >
                        <Alert severity='warning' sx={{ width: '100%' }}>
                            {t('HOME.SHORTCUT_LIST.NO_SHORTCUTS.EMPTY_FOLDER')}
                        </Alert>
                    </Slide>
                )}
                {loading ? (
                    <Skeleton sx={{ width: '100%', height: '56px' }} />
                ) : (
                    <Slide
                        timeout={500}
                        direction={slideDirection}
                        in={shortcutFolders.length > 0 || shortcuts.length > 0}
                        container={containerRef.current}
                    >
                        <Stack
                            sx={{
                                flexGrow: 1,
                                width: '100%',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}
                            gap={2}
                        >
                            {shortcutFolders.map((shortcutFolder) =>
                                shortcutFolder.displayAsHeader ? (
                                    <ShortcutFolderHeader
                                        shortcutFolder={shortcutFolder}
                                        application={userApplications.find(
                                            (app) => app.name === shortcutFolder.applicationName,
                                        )}
                                        onClick={updateBucketList}
                                        key={shortcutFolder.name}
                                    />
                                ) : (
                                    <ShortcutFolderCard
                                        shortcutFolder={shortcutFolder}
                                        onClick={updateBucketList}
                                        key={shortcutFolder.name}
                                    />
                                ),
                            )}
                            {shortcuts.map((shortcut) => (
                                <ShortcutCard shortcut={shortcut} key={shortcutGroup.name} />
                            ))}
                        </Stack>
                    </Slide>
                )}
            </Stack>
        </ModalDrawerContainer>
    );
};

export default ShortcutGroupDialog;
