import { Alert, Box, Button, Snackbar, Stack, Typography } from '@mui/material';
import { BucketNode } from 'models/BucketModel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LaunchIcon from '@mui/icons-material/Launch';
import useApplicationLink from 'hooks/useApplicationLink';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMobileView from 'hooks/useMobileView';
import { Link } from 'react-router-dom';

interface BucketCardProps {
    bucket: BucketNode;
    onClick: (bucket: BucketNode) => void;
}

export const BucketLinkContainer = ({
    bucket,
    children,
}: {
    bucket: BucketNode;
    children: ReactNode;
}) => {
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
    };

    return bucket.link ? (
        <Link to={bucket.link} onClick={handleLinkClick}>
            {children}
        </Link>
    ) : (
        <>{children}</>
    );
};

const BucketContent = ({ bucket, children }: { bucket: BucketNode; children?: ReactNode }) => {
    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ width: '100%', textAlign: 'left' }}
        >
            <Stack>
                <Typography fontWeight='bold' fontSize='16px'>
                    {bucket.displayName}
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        color: (theme) => theme.palette.text.secondary,
                    }}
                >
                    {bucket.description}
                </Typography>
            </Stack>
            {children}
        </Stack>
    );
};

const BucketCard = (props: BucketCardProps) => {
    const { bucket, onClick } = props;
    const { t } = useTranslation();
    const [warningOpen, setWarningOpen] = useState(false);
    const isMobileView = useMobileView();
    const linkOut = useApplicationLink(bucket.link);

    const handleClick = (bucket: BucketNode) => () => {
        if (isMobileView && !bucket.mobileFriendly) {
            setWarningOpen(true);
        } else if (bucket.link) {
            window.open(linkOut(), '_blank');
        } else {
            onClick(bucket);
        }
    };

    return (
        <BucketLinkContainer bucket={bucket}>
            {bucket.link || bucket.children.length > 0 ? (
                <Button
                    key={bucket.name}
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        textTransform: 'none',
                        p: 2,
                        borderRadius: '8px',
                        backgroundColor: (theme) => theme.palette.background.default,
                        borderColor: (theme) => theme.palette.divider,
                        ':hover': {
                            backgroundColor: (theme) => theme.palette.action.hover,
                            cursor: 'pointer',
                        },
                        width: '100%',
                    }}
                    onClick={handleClick(bucket)}
                    data-element-id={bucket.link}
                    variant='outlined'
                >
                    <BucketContent bucket={bucket}>
                        {bucket.link ? (
                            <LaunchIcon
                                fontSize='small'
                                sx={{
                                    color: (theme) => theme.palette.action.active,
                                }}
                            />
                        ) : (
                            <NavigateNextIcon
                                fontSize='small'
                                sx={{
                                    color: (theme) => theme.palette.action.active,
                                }}
                            />
                        )}
                    </BucketContent>
                </Button>
            ) : (
                <Box
                    key={bucket.name}
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                        textTransform: 'none',
                        p: 2,
                        borderRadius: '8px',
                        backgroundColor: (theme) => theme.palette.background.default,
                        borderColor: (theme) => theme.palette.divider,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        width: '100%',
                    }}
                >
                    <BucketContent bucket={bucket} />
                </Box>
            )}
            <Snackbar open={warningOpen} autoHideDuration={6000}>
                <Alert severity='warning' sx={{ width: '100%' }}>
                    {t('HOME.BUCKETS.MOBILE_UNFRIENDLY')}
                </Alert>
            </Snackbar>
        </BucketLinkContainer>
    );
};

export default BucketCard;
