import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as PoweredByNcrIconSvg } from './PoweredByNcrIcon.svg';

const PoweredByNcrIcon = () => {
    const theme = useTheme();

    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            columnGap={1}
            sx={{
                ':hover': {
                    cursor: 'default',
                },
                svg: {
                    fill: theme.palette.text.secondary,
                },
            }}
        >
            <Stack direction='row' sx={{ width: '84px' }}>
                <PoweredByNcrIconSvg />
            </Stack>
        </Stack>
    );
};

export default PoweredByNcrIcon;
