import { Box, Theme } from '@mui/material';
import { PropsWithChildren, useContext, useLayoutEffect } from 'react';
import useMobileView from 'hooks/useMobileView';
import { isAuthorized, UserTenantContext } from 'contexts/UserTenantContext';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import { AppHeader } from './app-header/AppHeader';
import AppDrawer from './app-drawer/AppDrawer';
import AppFooter from './app-footer/AppFooter';
import { SidebarMenu } from './app-drawer/SidebarMenu';
import { useSidenav } from '../contexts/SidenavContext';
import useLaptopView from '../hooks/useLaptopView';

interface AppLayoutProps extends PropsWithChildren {
    sidebarMenu: SidebarMenu[];
}

const AppLayout = ({ sidebarMenu, children }: AppLayoutProps) => {
    const isMobile = useMobileView();
    const { userTenantAuthorized } = useContext(UserTenantContext);
    const { isLoading, hasPreloaded } = useOrganizationContext();
    const { isSidenavOpen, closeSidenav } = useSidenav();
    const isLaptop = useLaptopView();

    useLayoutEffect(
        function useUnauthorizedLayout() {
            const unauthorizedLayout = () => {
                if (!isLoading && hasPreloaded && !isAuthorized(userTenantAuthorized)) {
                    closeSidenav();
                }
            };

            unauthorizedLayout();
        },
        [closeSidenav, userTenantAuthorized, isLoading, hasPreloaded],
    );

    useLayoutEffect(
        function useFooterLayout() {
            const shiftFooterOnSidebarOpen = () => {
                document.documentElement.style.setProperty(
                    '--app-footer-sidebar-margin',
                    isSidenavOpen ? `var(--app-drawer-width)` : '0px',
                );
            };
            shiftFooterOnSidebarOpen();

            return () => {
                document.documentElement.style.setProperty('--app-footer-sidebar-margin', '0px');
            };
        },
        [isSidenavOpen],
    );

    return (
        <>
            <AppHeader />
            <Box
                sx={(theme: Theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.background.paper,
                    minHeight: 'calc(100% - var(--app-header-height))',
                    px: isLaptop ? 'var(--app-padding-side-laptop)' : 'var(--app-padding-side)',
                    pt: 'var(--app-padding-header)',
                    pb: 'var(--app-padding-footer)',
                    overflowY: 'auto',
                    width: `calc(100% - ${isSidenavOpen && !isMobile ? 'var(--app-drawer-width)' : 0}px)`,
                    paddingTop: 'var(--app-header-height)',
                    marginLeft: isSidenavOpen && !isMobile ? `var(--app-drawer-width)` : 0,
                })}
            >
                <AppDrawer sidebarMenu={sidebarMenu} />
                {children}
            </Box>
            <AppFooter />
        </>
    );
};

export default AppLayout;
