import { Box, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import ApplicationCard from './application-card/ApplicationCard';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { HomeUserApplicationContext } from 'contexts/HomeUserApplicationContext';
import EmptyContentCard from 'components/empty-content-card/EmptyContentCard';
import AppCategoriesBar from '../AppCategoryFilterChips/AppCategoriesBar';
import { ApplicationCategoryModel } from '../../models/ApplicationCategoryModel';
import { ApplicationModel } from '../../models/ApplicationModel';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';

const DEFAULT_CATEGORY_FILTER: ApplicationCategoryModel = {
    id: -1,
    categoryName: 'All',
};

const ApplicationList = () => {
    const { t } = useTranslation();
    const { userApplications, hasApplications, loading, userCategories } = useContext(
        HomeUserApplicationContext,
    );
    const enrichedUserMappedCategories = [DEFAULT_CATEGORY_FILTER, ...userCategories];
    const { organization } = useOrganizationContext();
    const [selectedCategory, setSelectedCategory] =
        useState<ApplicationCategoryModel>(DEFAULT_CATEGORY_FILTER);
    const [filteredApplicationsList, setFilteredApplicationsList] =
        useState<ApplicationModel[]>(userApplications);

    useEffect(
        function getFilteredApplications() {
            if (selectedCategory.id !== DEFAULT_CATEGORY_FILTER.id && organization) {
                setFilteredApplicationsList(
                    userApplications.filter(
                        (appModel) =>
                            appModel.applicationCategories &&
                            appModel.applicationCategories.some(
                                (appCategory) =>
                                    appCategory.categoryName === selectedCategory.categoryName,
                            ),
                    ),
                );
            } else {
                setFilteredApplicationsList(userApplications);
            }
        },
        [selectedCategory, userApplications, organization],
    );

    if (loading) {
        return (
            <Stack gap={2}>
                <Skeleton sx={{ height: '32px' }} />
                <Skeleton sx={{ height: '170px' }} />
            </Stack>
        );
    }

    if (!hasApplications) {
        return (
            <EmptyContentCard
                title={t('HOME.APPLICATIONS.NO_APPS.TITLE')}
                subtitle={t('HOME.APPLICATIONS.NO_APPS.SUBTITLE')}
            />
        );
    }

    const handleCategorySelect = (category: ApplicationCategoryModel) => {
        setSelectedCategory(category);
    };

    return (
        <Box display='flex' flexDirection='column' gap={2}>
            {userApplications.length !== 1 && enrichedUserMappedCategories.length >= 2 && (
                <AppCategoriesBar
                    categories={enrichedUserMappedCategories}
                    onFilterChange={handleCategorySelect}
                />
            )}
            {filteredApplicationsList.length > 0 ? (
                <Stack direction='row' gap={2} flexWrap='wrap'>
                    {filteredApplicationsList.map((app) => (
                        <ApplicationCard application={app} key={app.name} />
                    ))}
                </Stack>
            ) : (
                <EmptyContentCard
                    title={t('HOME.APPLICATIONS.NO_APPS.TITLE')}
                    subtitle={t('HOME.APPLICATIONS.NO_APPS.FILTER_SUBTITLE')}
                />
            )}
        </Box>
    );
};

export default ApplicationList;
