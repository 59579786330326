import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import shortcutService from 'services/shorcuts.service';
import { ShortcutModel } from 'models/ShortcutModel';
import ShortcutCard from './ShortcutCard';
import { HomeUserApplicationContext } from 'contexts/HomeUserApplicationContext';
import EmptyContentCard from 'components/empty-content-card/EmptyContentCard';

const featuredShortcutNames = [
    'manage-invoices',
    'run-common-reports',
    'manage-users',
    'manage-schedule',
];

const ShortcutList = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { organization } = useOrganizationContext();
    const [shortcuts, setShortcuts] = useState<ShortcutModel[]>([]);
    const [featuredShortcuts, setFeaturedShortcuts] = useState<ShortcutModel[]>([]);
    const { userApplications } = useContext(HomeUserApplicationContext);

    useEffect(
        function getShortcuts() {
            if (organization?.organizationName && Boolean(userApplications.length)) {
                setLoading(true);
                shortcutService
                    .getShortcuts(organization.organizationName)
                    .then((response) => {
                        setShortcuts(
                            response.data.filter(
                                (item) => !featuredShortcutNames.includes(item.name),
                            ),
                        );
                        setFeaturedShortcuts(
                            response.data.filter((item) =>
                                featuredShortcutNames.includes(item.name),
                            ),
                        );
                    })
                    .catch(() => {})
                    .finally(() => setLoading(false));
            }
        },
        [organization?.organizationName, userApplications],
    );

    if (loading) {
        return <Skeleton sx={{ height: '170px' }} />;
    }

    if (shortcuts.length === 0) {
        return (
            <EmptyContentCard
                iconBefore={
                    <img
                        src={'/images/no-shortcuts-icon-light.svg'}
                        alt='No shortcuts'
                        height={64}
                        width={64}
                    />
                }
                title={t('HOME.SHORTCUT_LIST.NO_SHORTCUTS.TITLE')}
                subtitle={t('HOME.SHORTCUT_LIST.NO_SHORTCUTS.SUBTITLE')}
            />
        );
    }

    if (featuredShortcuts.length > 0) {
        if (featuredShortcuts.length > 3) {
            setShortcuts((shortcuts) => [...shortcuts, ...featuredShortcuts.slice(3)]);
            setFeaturedShortcuts((featuredShortcuts) => [...featuredShortcuts.slice(0, 3)]);
        }

        return (
            <Stack gap={2} width='100%'>
                {!loading && (
                    <Stack direction='row' gap={2} flexWrap='wrap'>
                        {featuredShortcuts.map((featuredShortcut) => (
                            <ShortcutCard
                                isFeaturedShortcut={true}
                                shortcut={featuredShortcut}
                                key={featuredShortcut.name}
                            />
                        ))}
                    </Stack>
                )}
                {!loading && (
                    <Stack direction='row' gap={2} flexWrap='wrap'>
                        {shortcuts.map((shortcut) => (
                            <ShortcutCard shortcut={shortcut} key={shortcut.name} />
                        ))}
                    </Stack>
                )}
            </Stack>
        );
    }

    return (
        <Stack gap={2} width='100%'>
            {!loading && (
                <Stack direction='row' gap={2} flexWrap='wrap'>
                    {shortcuts.map((shortcut) => (
                        <ShortcutCard shortcut={shortcut} key={shortcut.name} />
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default ShortcutList;
