import { useTranslation } from 'react-i18next';
import CommonErrorPage from './CommonErrorPage';
import { useTheme } from '@mui/material';

const UnexpectedErrorPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <CommonErrorPage
            imageUrl={`/images/unexpected-error-${theme.palette.mode}.svg`}
            title={t('ERROR_PAGE.500.TITLE')}
            subtitle={t('ERROR_PAGE.500.SUBTITLE')}
            showReturnHomeButton
        />
    );
};

export default UnexpectedErrorPage;
