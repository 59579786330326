import {
    ApplicationsContextProvider,
    RccContextConfig,
    RccContextProvider,
} from '@ncr-voyix-commerce/react-common-components';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import {
    HomeUserApplicationContext,
    HomeUserApplicationsProvider,
} from 'contexts/HomeUserApplicationContext';
import { i18nHome } from 'i18n/config';
import { I18nextProvider } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { AppLoader } from './components/app-loader/AppLoader';
import GoogleTagManager from './components/google-tag-manager/GoogleTagManager.';
import { ThemeProvider } from './components/providers/ThemeProvider';
import { oktaAuthOptions } from './constants/okta-auth-config';
import { UserContextProvider } from './contexts/UserTenantContext';
import AppRoutes from './components/route/Routes';
import { SidebarMenu } from './layout/app-drawer/SidebarMenu';
import { Fragment, useContext } from 'react';
import AppLayout from 'layout/AppLayout';
import { SideNavProvider } from './contexts/SidenavContext';
import { getEnvironment } from 'constants/application-config';

const sharedConfig: RccContextConfig = {
    language: 'en',
    environment: getEnvironment(),
};

const oktaAuth = new OktaAuth(oktaAuthOptions);

function App() {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    sharedConfig.routerOverrides = { navigate: navigate };

    return (
        <Fragment>
            <GoogleTagManager />
            <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
                <ThemeProvider>
                    <I18nextProvider i18n={i18nHome}>
                        <RccContextProvider config={sharedConfig}>
                            <ApplicationsContextProvider defaultApp='HOME'>
                                <HomeUserApplicationsProvider>
                                    <UserContextProvider>
                                        <AppLoader>
                                            <SideNavProvider>
                                                <AppContent />
                                            </SideNavProvider>
                                        </AppLoader>
                                    </UserContextProvider>
                                </HomeUserApplicationsProvider>
                            </ApplicationsContextProvider>
                        </RccContextProvider>
                    </I18nextProvider>
                </ThemeProvider>
            </Security>
        </Fragment>
    );
}

const HomeSidebarMenuList = (hasUserApps: boolean) => [
    new SidebarMenu('SIDEBAR.HOME', '/home', true),
    new SidebarMenu('SIDEBAR.APP_HUB', '/all-applications', hasUserApps),
];

function AppContent() {
    const { hasApplications } = useContext(HomeUserApplicationContext);

    return (
        <AppLayout sidebarMenu={HomeSidebarMenuList(hasApplications)}>
            <AppRoutes />
        </AppLayout>
    );
}

export default App;
