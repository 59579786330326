import { AppBar, Box, IconButton, Stack, Theme, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useOktaAuth } from '@okta/okta-react';
import useMobileView from 'hooks/useMobileView';
import {
    ApplicationSwitcher,
    OrganizationSwitcher,
    UserProfileSettings,
} from '@ncr-voyix-commerce/react-common-components';
import { useTranslation } from 'react-i18next';
import { hasNoOrganization, UserTenantContext } from 'contexts/UserTenantContext';
import { useContext } from 'react';
import { useSidenav } from '../../contexts/SidenavContext';


export const AppHeader = () => {
    const { authState } = useOktaAuth();
    const isMobile = useMobileView();
    const { t } = useTranslation();
    const { userTenantAuthorized } = useContext(UserTenantContext);
    const {isSidenavOpen, toggleSidenav} = useSidenav();
    if (!authState?.isAuthenticated) {
        return (
            <Box
                sx={{
                    height: 'var(--app-header-height)',
                }}
            />
        );
    }

    return (
        <AppBar
            sx={(theme: Theme) => ({
                height: 'var(--app-header-height)',
                paddingRight: 'var(--app-padding-side)',
                paddingLeft: isMobile ? '-4px ' : 'var(--app-padding-side)',
                py: 0.5,
                top: 0,
                boxShadow: 'none',
                left: 0,
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
                rowGap: 1,
                columnGap: 2,
                bgcolor: theme.palette.background.paper,
                backgroundImage: 'none',
                flexDirection: 'row',
                alignItems: 'center',
                width: `calc(100% - ${isSidenavOpen && !isMobile ? 'var(--app-drawer-width)' : 0}px)`,
                marginLeft: isSidenavOpen && !isMobile ? `var(--app-drawer-width)` : 0,
                position: 'fixed',
                zIndex: 2,
            })}
        >
            <Stack direction='row' sx={{ width: 1, justifyContent: 'flex-start' }}>
                {isMobile ? (
                    <Box height='100%'>
                        {!hasNoOrganization(userTenantAuthorized) && (
                                <ApplicationSwitcher isDisplayMode={true} onClick={toggleSidenav} />
                        )}
                    </Box>
                ) : (
                    <Box height='100%'>
                        {!hasNoOrganization(userTenantAuthorized) && (
                            <Tooltip
                                title={isSidenavOpen ? t('TOOLTIP.CLOSE_MENU') : t('TOOLTIP.OPEN_MENU')}
                            >
                                <IconButton
                                    color='inherit'
                                    aria-label='toggle drawer'
                                    edge='start'
                                    onClick={toggleSidenav}
                                    sx={{
                                        color: 'inherit',
                                    }}
                                >
                                    {isSidenavOpen ? <MenuOpenIcon /> : <MenuIcon />}
                                </IconButton>
                            </Tooltip>
                        )}
                        {!isSidenavOpen && <ApplicationSwitcher />}
                    </Box>
                )}
            </Stack>
            <Stack
                direction='row'
                sx={{
                    width: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <OrganizationSwitcher config={{ showStartIconOnly: isMobile }} />
                <UserProfileSettings />
            </Stack>
        </AppBar>
    );
};
