import client from './client';
import { ShortcutModel } from 'models/ShortcutModel';
import { getBaseUrl } from 'constants/backend-config';
import { BucketNode } from 'models/BucketModel';
import { ShortcutGroupModel } from 'models/ShortcutGroupModel';
import { ShortcutFolderModel } from 'models/ShortcutFolderModel';

const getShortcuts = async (orgName: string) => {
    return await client.get<Array<ShortcutModel>>(`${getBaseUrl()}/shortcuts`, {
        withCredentials: true,
        headers: {
            'nep-organization': orgName,
        },
    });
};

const getShortcutBuckets = async (orgName: string, shortcutName: string) => {
    return await client.get<Array<BucketNode>>(
        `${getBaseUrl()}/shortcuts/${shortcutName}/buckets`,
        {
            withCredentials: true,
            headers: {
                'nep-organization': orgName,
            },
        },
    );
};

const getShortcutGroups = async (orgName: string) => {
    return await client.get<Array<ShortcutGroupModel>>(`${getBaseUrl()}/shortcut-groups`, {
        withCredentials: true,
        headers: {
            'nep-organization': orgName,
        },
    });
};

const getShortcutGroupByName = async (orgName: string, groupName: string) => {
    return await client.get<ShortcutGroupModel>(`${getBaseUrl()}/shortcut-groups/${groupName}`, {
        withCredentials: true,
        headers: {
            'nep-organization': orgName,
        },
    });
};

const getShortcutFolderByName = async (orgName: string, folderName: string) => {
    return await client.get<ShortcutFolderModel>(`${getBaseUrl()}/shortcut-folders/${folderName}`, {
        withCredentials: true,
        headers: {
            'nep-organization': orgName,
        },
    });
};

const shortcutService = {
    getShortcuts,
    getShortcutBuckets,
    getShortcutGroups,
    getShortcutGroupByName,
    getShortcutFolderByName,
};

export default shortcutService;
