import { Button, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ShortcutFolderModel } from 'models/ShortcutFolderModel';

interface ShortcutFolderCardProps {
    shortcutFolder: ShortcutFolderModel;
    onClick: (folder: string) => void;
}

const ShortcutFolderCard = (props: ShortcutFolderCardProps) => {
    const { shortcutFolder, onClick } = props;

    return (
        <Button
            key={shortcutFolder.name}
            sx={{
                color: (theme) => theme.palette.text.primary,
                textTransform: 'none',
                p: 2,
                borderRadius: '8px',
                backgroundColor: (theme) => theme.palette.background.default,
                borderColor: (theme) => theme.palette.divider,
                ':hover': {
                    backgroundColor: (theme) => theme.palette.action.hover,
                    cursor: 'pointer',
                },
                width: '100%',
            }}
            onClick={() => onClick(shortcutFolder.name)}
            variant='outlined'
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ width: '100%', textAlign: 'left' }}
            >
                <Stack>
                    <Typography fontWeight='bold' fontSize='16px'>
                        {shortcutFolder.displayName}
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            color: (theme) => theme.palette.text.secondary,
                        }}
                    >
                        {shortcutFolder.description}
                    </Typography>
                </Stack>
                <NavigateNextIcon
                    fontSize='small'
                    sx={{
                        color: (theme) => theme.palette.action.active,
                    }}
                />
            </Stack>
        </Button>
    );
};

export default ShortcutFolderCard;
