import { useTheme } from '@mui/material';
import {
    QueryParamKeys,
    useOrganizationContext,
    useUserPreferencesContext,
} from '@ncr-voyix-commerce/react-common-components';
import { useOktaAuth } from '@okta/okta-react';
import Loading from 'components/loading/Loading';
import { ColorModeContext } from 'components/providers/ThemeProvider';
import { ReactNode, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FdTheme } from 'theme/theme-util';
import { UserTenantAuthorization, UserTenantContext } from 'contexts/UserTenantContext';
import useMediaQuery from '@mui/material/useMediaQuery';

const useSystemColorScheme = (): 'light' | 'dark' => {
    const systemColorScheme = useMediaQuery('(prefers-color-scheme: dark)');
    return systemColorScheme ? 'dark' : 'light';
};

export const AppLoader = ({ children }: { children: ReactNode }) => {
    const { authState } = useOktaAuth();
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const [searchParams, setSearchParams] = useSearchParams({});
    const { updateOrganization, setIsOrgDialogOpen } = useOrganizationContext();
    const { setUserTenantAuthorized } = useContext(UserTenantContext);
    const { userPreferences, isUserPreferencesLoading } = useUserPreferencesContext();
    const systemColorScheme = useSystemColorScheme();

    useEffect(() => {
        if (authState?.isAuthenticated && authState.accessToken) {
            const userPreferencesTheme: 'light' | 'dark' =
                userPreferences === undefined || userPreferences.theme === 'system'
                    ? systemColorScheme
                    : userPreferences.theme;

            if (theme.palette.mode !== FdTheme[userPreferencesTheme]) {
                colorMode.setColorMode(userPreferencesTheme);
            }

            if (
                userPreferences?.defaultOrganizationId &&
                !searchParams.get(QueryParamKeys.organization)
            ) {
                searchParams.set(
                    QueryParamKeys.organization,
                    userPreferences.defaultOrganizationId,
                );
                setSearchParams(searchParams);
                updateOrganization(userPreferences.defaultOrganizationId);
                setIsOrgDialogOpen(false);
            }
        } else {
            setUserTenantAuthorized(UserTenantAuthorization.Unset);
        }
    }, [authState, userPreferences, systemColorScheme]);

    if (isUserPreferencesLoading) {
        return <Loading />;
    }

    return <>{children}</>;
};
