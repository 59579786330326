import { AxiosError } from 'axios';
import NotFoundPage from './views/NotFoundPage';
import UnauthorizedPage from './views/UnauthorizedPage';
import CouldNotLoadPage from './views/CouldNotLoadPage';
import UnexpectedErrorPage from './views/UnexpectedErrorPage';

type FallbackProps = {
    error: any;
};

const AuthenticatedFallbackComponent = (props: FallbackProps) => {
    let { error } = props;

    if (
        (error instanceof AxiosError && error.response?.status === 401)
    ) {
        return <UnauthorizedPage />;
    }

    if (error instanceof AxiosError && error.response?.status === 404) {
        return <NotFoundPage />;
    }

    if (error instanceof AxiosError && error.response?.status === 500) {
        return <UnexpectedErrorPage />;
    }

    return <CouldNotLoadPage />;
};

export default AuthenticatedFallbackComponent;
