import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useLaptopView from '../hooks/useLaptopView';
import useTabletView from '../hooks/useTabletView';

interface SidenavContextType {
    isSidenavOpen: boolean;
    toggleSidenav: () => void;
    openSidenav: () => void;
    closeSidenav: () => void;
}

const SidenavContext = createContext<SidenavContextType | undefined>(undefined);

export const SideNavProvider = ({ children }: { children: ReactNode }) => {
    const isMobileView = useLaptopView();
    const isTabletView = useTabletView();
    const isLaptopView = useLaptopView();
    const [isSidenavOpen, setIsSidenavOpen] = useState<boolean>(isLaptopView);

    const toggleSidenav = useCallback(() => {
        setIsSidenavOpen((prev) => !prev);
    }, []);

    const openSidenav = useCallback(() => {
        setIsSidenavOpen(true);
    }, []);

    const closeSidenav = useCallback(() => {
        setIsSidenavOpen(false);
    }, []);

    useEffect(() => {
        if (isLaptopView) {
            openSidenav();
        } else {
            closeSidenav();
        }
    }, [isMobileView, isTabletView, isLaptopView]);

    const returnObj = useMemo(
        () => ({ isSidenavOpen, toggleSidenav, openSidenav, closeSidenav }),
        [isSidenavOpen, toggleSidenav, openSidenav, closeSidenav],
    );

    return <SidenavContext.Provider value={returnObj}>{children}</SidenavContext.Provider>;
};

export const useSidenav = (): SidenavContextType => {
    const context = useContext(SidenavContext);
    if (context === undefined) {
        throw new Error('useSidenav must be used within SidenavProvider');
    }
    return context;
};
