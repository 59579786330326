import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const i18nHome = i18n.use(initReactI18next).createInstance({
    resources: {
        en: {
            translation: require('./locales/en/en_US.json'),
        },
    },
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});
i18nHome.init();
