import { Box, Paper, useTheme } from '@mui/material';

export const AppIcon = ({
    src,
    appName,
    size = 'small',
}: {
    src?: string;
    appName?: string;
    size?: 'small' | 'large';
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const backupSrc = isDarkMode
        ? '/images/app-placeholder-dark.png'
        : '/images/app-placeholder-light.png';
    const logoSrc = src ?? backupSrc;
    const alt = appName && src ? `${appName} application icon` : 'Placeholder application icon';

    return (
        <Paper
            variant='outlined'
            sx={{
                overflow: 'hidden',
                flexShrink: 0,
                border: `0.5px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.secondary.light,
                width: size === 'small' ? '32px' : '52px',
                height: size === 'small' ? '32px' : '52px',
            }}
        >
            <Box
                component='img'
                sx={{ objectFit: 'cover' }}
                src={logoSrc}
                width='100%'
                height='100%'
                alt={alt}
            />
        </Paper>
    );
};
