import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { getGoogleTagManagerId } from 'constants/frontend-config';

const GoogleTagManager = () => {
    useEffect(() => {
        let gtmId = getGoogleTagManagerId();

        if (!gtmId) return;
        TagManager.initialize({
            gtmId: gtmId,
        });
    }, []);
    return null;
};

export default GoogleTagManager;
