import { useCallback, useContext, useEffect, useState } from 'react';
import { HomeUserApplicationContext } from 'contexts/HomeUserApplicationContext';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import { ApplicationModel, RecentApplicationModel } from 'models/ApplicationModel';
import applicationService from 'services/applications.service';

const useRecentApplications = () => {
    const [recentApplicationData, setRecentApplicationData] = useState<RecentApplicationModel[]>(
        [],
    );
    const [recentApplications, setRecentApplications] = useState<ApplicationModel[]>([]);
    const [loading, setLoading] = useState(false);
    const { organization } = useOrganizationContext();
    const { userApplications } = useContext(HomeUserApplicationContext);

    useEffect(() => {
        if (organization?.organizationName) {
            setLoading(true);
            applicationService
                .getRecentApplications(organization.organizationName)
                .then((res) => {
                    setRecentApplicationData(res.data);
                })
                .catch(() => {})
                .finally(() => setLoading(false));
        }
    }, [organization?.organizationName]);

    useEffect(() => {
        const filteredApplications = recentApplicationData
            .map((recentApp) =>
                userApplications.find((userApp) => userApp.name === recentApp.applicationName),
            )
            .filter((app) => app !== undefined && Boolean(app.baseUrl)) as ApplicationModel[];

        setRecentApplications(filteredApplications);
    }, [recentApplicationData, userApplications]);

    return { recentApplications, loading };
};

export const usePushRecentApplication = () => {
    const { organization } = useOrganizationContext();

    return useCallback(
        (selectedApplication: ApplicationModel) => {
            if (!organization?.organizationName) {
                return;
            }
            applicationService.pushRecentApplication(
                organization.organizationName,
                selectedApplication.name,
            );
        },
        [organization?.organizationName],
    );
};

export default useRecentApplications;
