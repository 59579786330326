import { Components, PaletteMode, Theme } from '@mui/material';
import { customColors } from 'theme/theme-util';

export const MuiTooltipTheme = (
    mode: PaletteMode,
): Components<Omit<Theme, 'components'>>['MuiTooltip'] => ({
    defaultProps: {
        arrow: true,
    },
    styleOverrides: {
        tooltip: {
            backgroundColor: customColors.tooltipBackground[mode],
        },
        arrow: {
            color: customColors.tooltipBackground[mode],
        },
    },
});
