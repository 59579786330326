import { useOktaAuth } from '@okta/okta-react';
import AuthenticatedFallbackComponent from 'components/error-boundary/AuthenticatedFallbackComponent';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isAuthorized, UserTenantContext } from 'contexts/UserTenantContext';
import { SigninWithRedirectOptions } from '@okta/okta-auth-js/types/lib/oidc/types';
import { RegistrationQueryParams } from 'constants/registration-query-params';

export const ProtectedRoutes: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { userTenantAuthorized } = useContext(UserTenantContext);

    const newUserParam = new URLSearchParams(location.search).get(RegistrationQueryParams.NewUser);
    const email = new URLSearchParams(location.search).get(RegistrationQueryParams.Email);

    const signInOptions: SigninWithRedirectOptions = {
        extraParams: newUserParam
            ? {
                  newUser: 'true',
                  email: email || '',
              }
            : {},
        loginHint: email || '',
        originalUri: window.location.origin + window.location.pathname + location.search,
    };
    const login = async () => oktaAuth.signInWithRedirect(signInOptions);

    useEffect(() => {
        if (authState && !authState.isAuthenticated) {
            login();
        }

        if (
            authState &&
            authState.isAuthenticated &&
            location.pathname === '/' &&
            isAuthorized(userTenantAuthorized)
        ) {
            navigate('/home');
        }
    }, [navigate, authState, userTenantAuthorized]);

    return (
        <ErrorBoundary fallback={AuthenticatedFallbackComponent}>
            <Outlet />
        </ErrorBoundary>
    );
};
