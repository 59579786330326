import { Alert, Box, Snackbar, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Loading from 'components/loading/Loading';
import { Trans, useTranslation } from 'react-i18next';
import useMobileView from 'hooks/useMobileView';
import { useOrganizationContext, useRccContext } from '@ncr-voyix-commerce/react-common-components';
import { MainVoyixLogo } from 'components/logo/MainVoyixLogo';
import ShortcutList from 'components/shortcut-list/ShortcutList';
import RecentApplicationList from 'components/application-list/RecentApplicationList';
import ShortcutListV2 from 'components/shortcut-list-v2/ShortcutListV2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LdFlagKeys } from 'constants/launch-darkly-config';

export const HomePage = () => {
    const { error } = useOrganizationContext();
    const [isSnackOpen, setIsSnackOpen] = useState(false);
    const handleClose = () => setIsSnackOpen(false);
    const { userInfo } = useRccContext();
    const isMobile = useMobileView();
    const { t } = useTranslation();
    const ldFlags = useFlags();

    useEffect(() => {
        if (error) {
            setIsSnackOpen(true);
        }
    }, [error, setIsSnackOpen]);

    if (!userInfo) {
        return <Loading />;
    }

    return (
        <Stack gap={1}>
            <Stack
                direction='row'
                justifyContent='space-between'
                sx={{
                    width: '100%',
                    minWidth: { laptop: '67vw' },
                    maxWidth: { laptop: 'calc(100% - 120px)' },
                }}
            >
                <Typography variant='h4' alignSelf='flex-end' sx={{ pb: '35px' }}>
                    <Trans
                        i18nKey='HOME.TITLE'
                        count={userInfo.given_name ? 1 : 0}
                        values={{
                            name: userInfo.given_name,
                        }}
                        components={[
                            <Box
                                key={'name'}
                                component='span'
                                sx={{ fontWeight: 600, display: 'block' }}
                            />,
                        ]}
                    />
                </Typography>
                {!isMobile && <MainVoyixLogo />}
            </Stack>

            <Stack gap={3}>
                <Stack sx={{ gap: 1 }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                        {t('HOME.RECENT_APPLICATIONS_LIST.TITLE')}
                    </Typography>
                    <RecentApplicationList />
                </Stack>

                {!ldFlags[LdFlagKeys.homeShortcutsRemodel] && (
                    <Stack sx={{ gap: 1 }}>
                        <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                            {t('HOME.SHORTCUT_LIST.TITLE')}
                        </Typography>
                        <ShortcutList />
                    </Stack>
                )}

                {ldFlags[LdFlagKeys.homeShortcutsRemodel] && (
                    <Stack sx={{ gap: 1 }}>
                        <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                            {t('HOME.SHORTCUT_LIST.TITLE')}
                        </Typography>
                        <ShortcutListV2 />
                    </Stack>
                )}

                <Snackbar
                    open={isSnackOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    color='inherit'
                >
                    <Alert onClose={handleClose} severity='error'>
                        {error?.message}
                    </Alert>
                </Snackbar>
            </Stack>
        </Stack>
    );
};
