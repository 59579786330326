import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider as MuiTheme } from '@mui/material/styles';
import { createContext, ReactNode, useMemo, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { getDesignTokens } from 'theme/theme-util';

export const ColorModeContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setColorMode: (value: 'light' | 'dark') => {},
    toggleColorMode: () => {},
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light');

    const theme = useMemo(() => {
        return createTheme(getDesignTokens(mode));
    }, [mode]);

    const colorMode = useMemo(
        () => ({
            setColorMode: (newMode: 'light' | 'dark') => {
                setMode(newMode);
            },
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <MuiTheme theme={theme}>
                <CssBaseline />
                {children}
            </MuiTheme>
        </ColorModeContext.Provider>
    );
};
