import { useTranslation } from 'react-i18next';
import CommonErrorPage from './CommonErrorPage';
import { useTheme } from '@mui/material';

const NotFoundPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <CommonErrorPage
            imageUrl={`/images/page-not-found-${theme.palette.mode}.svg`}
            title={t('ERROR_PAGE.404.TITLE')}
            subtitle={t('ERROR_PAGE.404.SUBTITLE')}
            showReturnHomeButton
        />
    );
};

export default NotFoundPage;
