import { Icon, Paper, useTheme } from '@mui/material';
import { ShortcutGroupIconModel } from 'models/ShortcutGroupModel';

export const ShortcutGroupIcon = ({ icon }: { icon: ShortcutGroupIconModel }) => {
    const theme = useTheme();

    return (
        <Paper
            variant='outlined'
            sx={{
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '6px',
            }}
        >
            <Icon
                fontSize='small'
                sx={{
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? icon.colorDarkMode : icon.colorLightMode,
                }}
            >
                {icon.name}
            </Icon>
        </Paper>
    );
};
