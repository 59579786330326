import { Drawer } from '@mui/material';
import useMobileView from 'hooks/useMobileView';
import { SidebarMenu } from './SidebarMenu';
import Sidebar from './Sidebar';
import { useSidenav } from '../../contexts/SidenavContext';

interface AppDrawerProps {
    sidebarMenu: SidebarMenu[];
}
const AppDrawer = (props: AppDrawerProps) => {
    const { sidebarMenu } = props;
    const isMobile = useMobileView();
    const { isSidenavOpen, toggleSidenav } = useSidenav();

    return (
        <Drawer
            anchor={'left'}
            open={isSidenavOpen}
            variant={isMobile ? 'temporary' : 'persistent'}
            onClose={toggleSidenav}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: isMobile ? '100%' : '240px',
                },
            }}
        >
            <Sidebar sideBarMenuItems={sidebarMenu} />
        </Drawer>
    );
};

export default AppDrawer;
