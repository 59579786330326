import { hasNoOrganization, UserTenantContext } from "contexts/UserTenantContext";
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import UnauthorizedPage from "components/error-boundary/views/UnauthorizedPage";

export const AppAuthorizedGuard = () => {
    const { userTenantAuthorized } = useContext(UserTenantContext);

    if (hasNoOrganization(userTenantAuthorized)) {
        return <UnauthorizedPage />;
    }

    return <Outlet />;
};
