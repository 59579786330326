import { Typography, Stack, Button, Box, Slide, LinearProgress } from '@mui/material';
import { ShortcutModel } from 'models/ShortcutModel';
import { BucketNode } from 'models/BucketModel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BucketCard from './bucket-card/BucketCard';
import { ShortcutIcon } from 'components/shortcut-list/ShortcutIcon';
import ModalDrawerContainer from 'components/modal-drawer-container/ModalDrawerContainer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import shortcutService from 'services/shorcuts.service';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import BucketGroup from './bucket-group/BucketGroup';

interface ShortcutDialogProps {
    open: boolean;
    onClose: () => void;
    shortcut: ShortcutModel;
}

function getProgress(cur: number, max: number): number {
    return Math.max((cur / max) * 100, 5);
}

const ShortcutDialog = ({ open, onClose, shortcut }: ShortcutDialogProps) => {
    const [buckets, setBuckets] = useState<Array<BucketNode>>([]);
    const [loading, setLoading] = useState(false);
    const [bucketsHistory, setBucketsHistory] = useState<Array<Array<BucketNode>>>([]);
    const [currentBuckets, setCurrentBuckets] = useState<Array<BucketNode>>([]);
    const [step, setStep] = useState(0);
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const { organization } = useOrganizationContext();

    const maxDepthLeft = useCallback(() => {
        if (currentBuckets.length === 0) {
            return 0;
        }

        return currentBuckets.map((bucket) => bucket.branchDepth).reduce((a, b) => Math.max(a, b));
    }, [currentBuckets]);

    useEffect(() => {
        if (open && organization?.organizationName) {
            setLoading(true);
            shortcutService
                .getShortcutBuckets(organization?.organizationName, shortcut.name)
                .then((response) => {
                    setBuckets(response.data);
                    setCurrentBuckets(response.data.filter((bucket) => !bucket.parentBucketName));
                })
                .finally(() => setLoading(false));
        } else {
            setBucketsHistory([]);
            setCurrentBuckets([]);
            setBuckets([]);
        }
    }, [open, organization?.organizationName, shortcut.name]);

    function updateBucketList(bucket: BucketNode) {
        if (bucket.link) return;
        if (bucket.children.length === 0) return;

        setStep((prev) => prev + 1);
        setLoading(true);

        setCurrentBuckets((prevBuckets) => {
            setBucketsHistory((history) => {
                return history.concat([prevBuckets]);
            });

            return buckets.filter((b) => b.parentBucketName === bucket.name);
        });

        setTimeout(() => {
            setLoading(false);
        }, 25);
    }

    const backButtonClick = () => {
        setStep((prev) => prev - 1);
        setCurrentBuckets(bucketsHistory.at(-1) || []);
        setBucketsHistory((prev) => {
            return prev.slice(0, -1);
        });
    };

    return (
        <ModalDrawerContainer
            ref={containerRef}
            open={open}
            onClose={onClose}
            onOpen={() => {}}
            HeaderComponent={
                <Stack direction='row' gap={2} sx={{ alignItems: 'center' }}>
                    <ShortcutIcon icon={shortcut.icon} />
                    <Typography variant='h5' fontWeight='600' fontSize='16px'>
                        {shortcut.displayName}
                    </Typography>
                </Stack>
            }
            DividerComponent={
                maxDepthLeft() > 1 ? (
                    <LinearProgress
                        variant='determinate'
                        value={getProgress(step, maxDepthLeft())}
                    />
                ) : null
            }
            FooterComponent={
                Boolean(bucketsHistory.length) && (
                    <Box
                        height='84px'
                        alignContent='center'
                        boxShadow='0px -4px 20px 0px #00000014'
                    >
                        <Button onClick={backButtonClick} sx={{ marginLeft: '24px' }}>
                            <ArrowBackIcon
                                fontSize='small'
                                sx={{
                                    marginRight: '8px',
                                    fontSize: '15px',
                                }}
                            />
                            {t('COMMON.BACK')}
                        </Button>
                    </Box>
                )
            }
        >
            <Stack
                gap={2}
                sx={{
                    height: '100%',
                    minHeight: '400px',
                    justifyContent: 'space-between',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}
                alignItems='flex-start'
            >
                {shortcut.prompt && (
                    <Typography variant='h1' fontWeight='700' fontSize='25px'>
                        {shortcut.prompt}
                    </Typography>
                )}
                {!loading && (
                    <Slide
                        timeout={500}
                        direction='left'
                        in={currentBuckets.length > 0}
                        container={containerRef.current}
                    >
                        <Stack sx={{ flexGrow: 1, width: '100%', overflowY: 'auto' }} gap={2}>
                            {currentBuckets.map((bucket) => (
                                <>
                                    {bucket.isGroup ? (
                                        <BucketGroup
                                            bucket={bucket}
                                            onClick={updateBucketList}
                                            key={bucket.name}
                                        />
                                    ) : (
                                        <BucketCard
                                            bucket={bucket}
                                            onClick={updateBucketList}
                                            key={bucket.name}
                                        />
                                    )}
                                </>
                            ))}
                        </Stack>
                    </Slide>
                )}
            </Stack>
        </ModalDrawerContainer>
    );
};

export default ShortcutDialog;
