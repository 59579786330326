import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { BucketNode } from 'models/BucketModel';
import BucketCard, {
    BucketLinkContainer,
} from 'components/shortcut-list/shortcut-dialog/bucket-card/BucketCard';
import LaunchIcon from '@mui/icons-material/Launch';
import Stack from '@mui/material/Stack';
import useApplicationLink from 'hooks/useApplicationLink';
import { useState } from 'react';
import useMobileView from 'hooks/useMobileView';
import { useTranslation } from 'react-i18next';

interface BucketGroupProps {
    bucket: BucketNode;
    onClick: (bucket: BucketNode) => void;
}

const BucketGroup = (props: BucketGroupProps) => {
    const { bucket, onClick } = props;
    const { t } = useTranslation();
    const linkOut = useApplicationLink(bucket.link);
    const [warningOpen, setWarningOpen] = useState(false);
    const isMobileView = useMobileView();

    const handleClick = (bucket: BucketNode) => () => {
        if (isMobileView && !bucket.mobileFriendly) {
            setWarningOpen(true);
        } else if (bucket.link) {
            window.open(linkOut(), '_blank');
        } else {
            onClick(bucket);
        }
    };

    return (
        <Stack sx={{ flexGrow: 1, width: '100%' }} gap={2}>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Typography
                    fontWeight={'bold'}
                    fontSize='18px'
                    sx={{
                        color: (theme) => theme.palette.text.primary,
                    }}
                >
                    {bucket.displayName}
                </Typography>
                {bucket.link && !isMobileView && (
                    <BucketLinkContainer bucket={bucket}>
                        <Button
                            variant='text'
                            endIcon={<LaunchIcon />}
                            onClick={handleClick(bucket)}
                            sx={{ fontSize: '14px' }}
                        >
                            {t('HOME.BUCKETS.MORE_REPORTS')}
                        </Button>
                    </BucketLinkContainer>
                )}
            </Stack>
            {bucket.children.map((child) => (
                <BucketCard bucket={child} onClick={onClick} key={child.name} />
            ))}
            {bucket.link && isMobileView && (
                <BucketLinkContainer bucket={bucket}>
                    <Button
                        variant='outlined'
                        endIcon={<LaunchIcon />}
                        onClick={handleClick(bucket)}
                        sx={{
                            fontSize: '14px',
                            height: '44px',
                            borderRadius: '8px',
                        }}
                    >
                        {t('HOME.BUCKETS.MORE_REPORTS')}
                    </Button>
                </BucketLinkContainer>
            )}
            <Snackbar open={warningOpen} autoHideDuration={6000}>
                <Alert severity='warning' sx={{ width: '100%' }}>
                    {t('HOME.BUCKETS.MOBILE_UNFRIENDLY')}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default BucketGroup;
