import { ButtonBase, Paper, Stack, Typography, useTheme } from '@mui/material';
import { AppIcon } from 'components/app-icon/AppIcon';
import { ApplicationModel } from 'models/ApplicationModel';
import { usePushRecentApplication } from './useRecentApplications';
import useApplicationLink from 'hooks/useApplicationLink';

interface RecentApplicationCardProps {
    application: ApplicationModel;
}

const RecentApplicationCard = (props: RecentApplicationCardProps) => {
    const { application } = props;
    const theme = useTheme();
    const linkOut = useApplicationLink(application.baseUrl);

    const pushRecentApplication = usePushRecentApplication();

    return (
        <ButtonBase
            focusRipple={true}
            sx={{
                width: {
                    mobile: '100%',
                    tablet_lg: '48%',
                    tablet_md: '48%',
                    laptop: '30%',
                },
                borderRadius: '16px',
            }}
            onClick={() => {
                pushRecentApplication(application);
            }}
            href={linkOut()}
            target='_blank'
        >
            <Paper
                variant='outlined'
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    border: `0.5px solid ${theme.palette.divider}`,
                    alignItems: 'start',
                    borderRadius: '16px',
                    backgroundColor: theme.palette.background.default,
                    ':hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    sx={{
                        margin: '16px',
                        overflow: 'hidden',
                    }}
                >
                    <div>
                        <AppIcon
                            src={application.imageUrl}
                            appName={application.displayName || application.name}
                        />
                    </div>
                    <Typography variant='h6' fontWeight='600' fontSize='14px' marginTop='14px'>
                        {application.displayName}
                    </Typography>
                </Stack>
            </Paper>
        </ButtonBase>
    );
};

export default RecentApplicationCard;
