export class SidebarMenu {
    text: string;
    path: string;
    showMenuOption: boolean;

    constructor(text: string, path: string, showMenuOption: boolean) {
        this.text = text;
        this.path = path;
        this.showMenuOption = showMenuOption;
    }

    isMenuActive(currentPath: string): boolean {
        return currentPath.startsWith(this.path);
    }
}
