import React, { useEffect, useState } from 'react';
import { Box, Chip, Theme, Typography, useTheme } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { ApplicationCategoryModel } from 'models/ApplicationCategoryModel';
import useMobileView from 'hooks/useMobileView';
import { AppFilterMoreMenu } from './AppFilterMoreMenu';
import { AppFilterMoreDrawer } from './AppFilterMoreDrawer';
import { useSidenav } from 'contexts/SidenavContext';
import UseTabletView from 'hooks/useTabletView';

const MOBILE_CHIP_ROW_LENGTH = 1,
    TABLET_CHIP_ROW_LENGTH = 4,
    DESKTOP_CHIP_ROW_LENGTH = 6;

interface ProductFilterProps {
    categories: ApplicationCategoryModel[];
    onFilterChange: (selectedCategory: ApplicationCategoryModel) => void;
}

const AppCategoriesBar = ({ categories, onFilterChange }: ProductFilterProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = UseTabletView();
    const isMobile = useMobileView();
    const { isSidenavOpen } = useSidenav();
    const [chipsBarLength, setChipsBarLength] = useState<number>(1);
    const defaultCategory = categories[0];
    const [defaultSurfacedCategories, setDefaultSurfacedCategories] = useState<
        ApplicationCategoryModel[]
    >(categories.slice(0, chipsBarLength));
    const [selectedCategory, setSelectedCategory] =
        useState<ApplicationCategoryModel>(defaultCategory);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [visibleCategories, setVisibleCategories] = useState<ApplicationCategoryModel[]>([
        ...defaultSurfacedCategories,
    ]);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleChipClick = (category: ApplicationCategoryModel) => {
        if (selectedCategory?.id === category.id) {
            setVisibleCategories(defaultSurfacedCategories);
            setSelectedCategory(defaultCategory);
            onFilterChange(defaultCategory);
            return;
        }
        if (!defaultSurfacedCategories.some((c) => c.id === category.id)) {
            setVisibleCategories([...defaultSurfacedCategories, category]);
        } else {
            setVisibleCategories(defaultSurfacedCategories);
        }
        setSelectedCategory(category);
        onFilterChange(category);
    };

    const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
        setAnchorEl(null);
    };
    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const handleDropdownSelection = (category: ApplicationCategoryModel) => {
        handleChipClick(category);
        handleMenuClose();
    };

    const isCategorySelected = (category: ApplicationCategoryModel) =>
        selectedCategory?.id === category.id;

    useEffect(
        function determineChipsBarLength() {
            const categoriesLength = categories.length;
            if (isMobile) {
                setChipsBarLength(Math.min(MOBILE_CHIP_ROW_LENGTH, categoriesLength));
                return;
            }
            if (isTablet) {
                if (isSidenavOpen) {
                    setChipsBarLength(Math.min(MOBILE_CHIP_ROW_LENGTH, categoriesLength));
                } else {
                    setChipsBarLength(Math.min(TABLET_CHIP_ROW_LENGTH, categoriesLength));
                }
            } else {
                setChipsBarLength(Math.min(DESKTOP_CHIP_ROW_LENGTH, categoriesLength));
            }
        },
        [chipsBarLength, isMobile, isTablet, isSidenavOpen, categories],
    );

    useEffect(
        function useResponsiveChipLayout() {
            const responsiveCategoriesList = categories.slice(0, chipsBarLength);
            setDefaultSurfacedCategories(responsiveCategoriesList);
            if (!responsiveCategoriesList.some((c) => c.id === selectedCategory.id)) {
                setVisibleCategories([...responsiveCategoriesList, selectedCategory]);
            } else {
                setVisibleCategories(responsiveCategoriesList);
            }
        },
        [isMobile, categories, selectedCategory, chipsBarLength],
    );

    return (
        <Box
            display='flex'
            alignItems='center'
            gap={1}
            flexWrap='nowrap'
            maxWidth='auto'
            sx={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            {visibleCategories.map((category) => (
                <Chip
                    key={category.id}
                    label={
                        <Typography
                            fontWeight='500'
                            fontSize='14px'
                            color={
                                isCategorySelected(category)
                                    ? theme.palette.primary.contrastText
                                    : undefined
                            }
                        >
                            {category.categoryName}
                        </Typography>
                    }
                    onClick={() => handleChipClick(category)}
                    sx={(theme: Theme) => ({
                        backgroundColor: isCategorySelected(category)
                            ? theme.palette.primary.dark
                            : undefined,
                    })}
                />
            ))}
            {chipsBarLength < categories.length && (
                <>
                    <Chip
                        label={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <Typography fontWeight='500' fontSize='14px'>
                                    {isMobile
                                        ? t('HOME.APPLICATIONS.FILTER.MORE_CHIP_MOBILE')
                                        : t('HOME.APPLICATIONS.FILTER.MORE_CHIP_DESKTOP')}
                                </Typography>
                                {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        onClick={handleMoreClick}
                    />
                    {isMobile ? (
                        <AppFilterMoreDrawer
                            menuOpen={isMenuOpen}
                            onMenuClose={handleMenuClose}
                            onMenuOpen={handleMenuOpen}
                            categories={categories}
                            handleDropdownSelection={handleDropdownSelection}
                            isCategorySelected={isCategorySelected}
                            headerName={t('HOME.APPLICATIONS.FILTER.HEADER')}
                            footerName={t('HOME.APPLICATIONS.FILTER.FOOTER')}
                        />
                    ) : (
                        <AppFilterMoreMenu
                            anchorRef={anchorEl}
                            menuOpen={Boolean(anchorEl)}
                            onMenuClose={handleMenuClose}
                            categories={categories}
                            chipListLength={chipsBarLength}
                            handleDropdownSelection={handleDropdownSelection}
                            isCategorySelected={isCategorySelected}
                            headerName={t('HOME.APPLICATIONS.FILTER.HEADER')}
                            footerName={t('HOME.APPLICATIONS.FILTER.FOOTER')}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default AppCategoriesBar;
