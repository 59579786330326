import { Components, Theme } from '@mui/material';

export const MuiButtonTheme = (): Components<Omit<Theme, 'components'>>['MuiButton'] => ({
    styleOverrides: {
        root: {
            textTransform: 'none',
            boxShadow: 'none',
        },
    },
});
