export interface ApplicationModel {
    id: number;
    name: string;
    displayName: string;
    shortDescription?: string;
    redirectUrl: string;
    baseUrl: string;
    imageUrl: string;
    applicationCategories: ApplicationCategory[];
}

export interface ApplicationCategory {
    categoryName: string;
}

export function getApplicationType(application: ApplicationModel): string {
    if (!application.baseUrl) {
        return ApplicationType.OnPremise;
    }

    return ApplicationType.Cloud;
}

export enum ApplicationType {
    Legacy = 'Legacy',
    OnPremise = 'OnPremise',
    Cloud = 'Cloud',
}

export interface RecentApplicationModel {
    applicationName: string;
}
