import { ApplicationModel } from 'models/ApplicationModel';
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';
import applicationService from 'services/applications.service';
import applicationConfig from 'constants/application-config';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import { ApplicationCategoryModel } from '../models/ApplicationCategoryModel';

interface HomeUserApplicationsContextType {
    userApplications: ApplicationModel[];
    setUserApplications: Dispatch<SetStateAction<ApplicationModel[]>>;
    hasApplications: boolean;
    loading: boolean;
    userCategories: ApplicationCategoryModel[];
}

const initialContextValue: HomeUserApplicationsContextType = {
    userApplications: [],
    setUserApplications: () => {},
    hasApplications: false,
    loading: true,
    userCategories: [],
};

export const HomeUserApplicationContext =
    createContext<HomeUserApplicationsContextType>(initialContextValue);

export const HomeUserApplicationsProvider = ({ children }: { children: ReactNode }) => {
    const [userApplications, setUserApplications] = useState<ApplicationModel[]>([]);
    const { organization } = useOrganizationContext();
    const [loading, setLoading] = useState<boolean>(true);
    const [userCategories, setUserCategories] = useState<ApplicationCategoryModel[]>([]);

    useEffect(
        function getUserGrantApplication() {
            if (organization?.organizationName) {
                setLoading(true);
                applicationService
                    .getAllUserGrantApplications(organization.organizationName)
                    .then((res) => {
                        const filteredApplications = res.data.filter(
                            (app) => app.name !== applicationConfig.name,
                        );
                        filteredApplications.sort((a, b) => a.name.localeCompare(b.name));
                        setUserApplications(filteredApplications);
                    })
                    .catch((err) => {
                        throw err;
                    })
                    .finally(() => setLoading(false));
            }
        },
        [organization?.organizationName],
    );

    const mapUserAppCategories = useMemo(() => {
        const categorySet = new Set<string>();
        userApplications.forEach((app) => {
            if (app.applicationCategories) {
                app.applicationCategories.forEach((category) => {
                    categorySet.add(category.categoryName);
                });
            }
        });
        return categorySet;
    }, [userApplications]);

    useEffect(
        function getAppCategoriesListOrder() {
            if (userApplications.length <= 1) {
                setUserCategories([]);
                return;
            }
            setLoading(true);
            applicationService
                .getApplicationCategories()
                .then((res) => {
                    const coreCategories = res.data.pageContent;
                    setUserCategories(
                        coreCategories.filter((category) =>
                            mapUserAppCategories.has(category.categoryName),
                        ),
                    );
                })
                .catch(() => {})
                .finally(() => setLoading(false));
        },
        [userApplications, mapUserAppCategories],
    );

    const contextValue: HomeUserApplicationsContextType = useMemo(
        () => ({
            userApplications,
            setUserApplications,
            hasApplications: userApplications.length > 0,
            loading,
            userCategories,
        }),
        [userApplications, setUserApplications, loading, userCategories],
    );

    return (
        <HomeUserApplicationContext.Provider value={contextValue}>
            {children}
        </HomeUserApplicationContext.Provider>
    );
};
