import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { QueryParamKeys } from '@ncr-voyix-commerce/react-common-components';
import { SecuredRoutes } from 'constants/app-internal-links';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import uriQueryUtils from 'utils/uri-query.utils';

interface CommonErrorPageProps {
    imageUrl?: string;
    title: string;
    subtitle: string;
    showReturnHomeButton?: boolean;
    showReloadButton?: boolean;
}

const CommonErrorPage = (props: CommonErrorPageProps) => {
    const { imageUrl, title, subtitle, showReturnHomeButton, showReloadButton } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams({});
    const linkOut = uriQueryUtils.addQueryParams(
        SecuredRoutes.HomePage,
        QueryParamKeys.organization,
        searchParams.get(QueryParamKeys.organization) || '',
    );

    return (
        <Stack sx={{ alignItems: 'center', gap: 2, flexGrow: 1 }}>
            {imageUrl && <img src={imageUrl} alt={imageUrl} height={280} width={280} />}
            <Stack sx={{ alignItems: 'center' }}>
                <Typography variant='h5' sx={{ fontWeight: 600 }}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography
                        variant='subtitle1'
                        sx={{ color: theme.palette.text.secondary, textAlign: 'center' }}
                    >
                        {subtitle}
                    </Typography>
                )}
            </Stack>
            <Stack
                sx={{
                    flexGrow: 1,
                    justifyContent: {
                        mobile: 'flex-end',
                        tablet_md: 'flex-start',
                        tablet_lg: 'flex-start',
                    },
                    width: { mobile: '100%', tablet_lg: 'auto', tablet_md: 'auto' },
                    gap: 2,
                }}
            >
                {showReloadButton && (
                    <Button variant='contained' onClick={() => window.location.reload()}>
                        {t('ERROR_PAGE.RELOAD_BUTTON_CTA')}
                    </Button>
                )}
                {showReturnHomeButton && (
                    <Button variant={showReloadButton ? 'text' : 'contained'} href={linkOut}>
                        {t('ERROR_PAGE.BUTTON_CTA')}
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};

export default CommonErrorPage;
