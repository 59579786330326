import { getBaseUrl } from 'constants/backend-config';
import client from './client';
import { ApplicationModel, RecentApplicationModel } from 'models/ApplicationModel';
import { PaginatedResponse } from 'models/PaginatedResponse';
import { ApplicationCategoryModel } from 'models/ApplicationCategoryModel';

const getAllUserGrantApplications = async (orgName: string) => {
    return await client.get<ApplicationModel[]>(`${getBaseUrl()}/applications-cache/grants`, {
        withCredentials: true,
        headers: {
            'nep-organization': orgName,
        },
    });
};

const getRecentApplications = async (orgName: string) => {
    return await client.get<RecentApplicationModel[]>(
        `${getBaseUrl()}/applications-cache/recents`,
        {
            withCredentials: true,
            headers: {
                'nep-organization': orgName,
            },
        },
    );
};

const pushRecentApplication = async (orgName: string, applicationName: string) => {
    return await client.post(
        `${getBaseUrl()}/applications-cache/recent`,
        {
            applicationName: applicationName,
        },
        {
            withCredentials: true,
            headers: {
                'nep-organization': orgName,
            },
        },
    );
};

const getApplicationCategories = async () => {
    return await client.get<PaginatedResponse<ApplicationCategoryModel>>(
        `${getBaseUrl()}/applications/application-category`,
        {
            withCredentials: true,
        },
    );
};

const applicationService = {
    getRecentApplications,
    pushRecentApplication,
    getApplicationCategories,
    getAllUserGrantApplications
};

export default applicationService;
