import { Typography, ButtonBase, Paper, Box } from '@mui/material';
import { ShortcutModel } from 'models/ShortcutModel';
import { useState } from 'react';
import ShortcutDialog from './shortcut-dialog/ShortcutDialog';
import { useTheme } from '@mui/material/styles';
import { ShortcutIcon } from './ShortcutIcon';

const HEIGHT = 65.6;

interface ShortcutCardProps {
    shortcut: ShortcutModel;
    isFeaturedShortcut?: boolean;
}

const ShortcutCard = ({ shortcut, isFeaturedShortcut = false }: ShortcutCardProps) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (isFeaturedShortcut) {
        const shortcutUrl = `/images/${shortcut.name}.svg`;

        return (
            <>
                <ButtonBase
                    key={shortcut.name}
                    focusRipple={true}
                    sx={{
                        width: {
                            mobile: '100%',
                            tablet_lg: '48%',
                            tablet_md: '48%',
                            laptop: '30%',
                        },
                        borderRadius: '16px',
                    }}
                    onClick={handleClickOpen}
                >
                    <Paper
                        variant='outlined'
                        sx={{
                            width: '100%',
                            height: HEIGHT + 170,
                            display: 'flex',
                            flexDirection: 'column',
                            border: `0.5px solid ${theme.palette.divider}`,
                            alignItems: 'center',
                            borderRadius: '16px',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            ':hover': {
                                backgroundColor: theme.palette.action.hover,
                            },
                        }}
                    >
                        <Box sx={{ height: '70%' }}>
                            <Box
                                sx={{
                                    height: 'auto',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                <img src={shortcutUrl} height='225px' alt={shortcutUrl} />
                            </Box>
                        </Box>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: '50%',
                                width: '100%',
                                backgroundColor: theme.palette.background.default,
                                borderTop: `0.5px solid ${theme.palette.divider}`,
                                boxShadow: 'none',
                                backgroundImage: 'none',
                                padding: '16px',
                            }}
                        >
                            <ShortcutIcon icon={shortcut.icon} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    margin: '0 16px',
                                }}
                            >
                                <Typography variant='h6' fontWeight='600' fontSize='14px'>
                                    {shortcut.displayName}
                                </Typography>
                            </Box>
                        </Paper>
                    </Paper>
                </ButtonBase>
                {open && <ShortcutDialog shortcut={shortcut} open={open} onClose={handleClose} />}
            </>
        );
    }

    return (
        <>
            <ButtonBase
                key={shortcut.name}
                focusRipple={true}
                sx={{
                    width: {
                        mobile: '100%',
                        tablet_lg: '48%',
                        tablet_md: '48%',
                        laptop: '30%',
                    },
                    borderRadius: '16px',
                }}
                onClick={handleClickOpen}
            >
                <Paper
                    variant='outlined'
                    sx={{
                        width: '100%',
                        height: HEIGHT,
                        display: 'flex',
                        flexDirection: 'row',
                        border: `0.5px solid ${theme.palette.divider}`,
                        alignItems: 'center',
                        borderRadius: '16px',
                        backgroundColor: theme.palette.background.default,
                        ':hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                        padding: '16px',
                        justifyContent: 'flex-start',
                        overflow: 'hidden',
                    }}
                >
                    <ShortcutIcon icon={shortcut.icon} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            margin: '16px',
                        }}
                    >
                        <Typography variant='h6' fontWeight='600' fontSize='14px'>
                            {shortcut.displayName}
                        </Typography>
                    </Box>
                </Paper>
            </ButtonBase>
            {open && <ShortcutDialog shortcut={shortcut} open={open} onClose={handleClose} />}
        </>
    );
};
export default ShortcutCard;
