import { ButtonBase, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { ApplicationModel, ApplicationType, getApplicationType } from 'models/ApplicationModel';
import { usePushRecentApplication } from './useRecentApplications';
import useApplicationLink from 'hooks/useApplicationLink';
import textUtils from 'utils/text.utils';
import ApplicationDetailDialog from './application-detail-dialog/ApplicationDetailDialog';
import { AppIcon } from 'components/app-icon/AppIcon';
import { useState } from 'react';

const HEIGHT = 164;
const DESCRIPTION_LIMIT = 120;

interface ApplicationCardProps {
    application: ApplicationModel;
}

const getCardTooltipDescription = (application: ApplicationModel) => {
        return application.shortDescription && application.shortDescription.length <= DESCRIPTION_LIMIT ? undefined : (
            <span>
                <b>{application.displayName}:</b> {application.shortDescription}
            </span>
        );
};

const ApplicationCard = (props: ApplicationCardProps) => {
    const { application } = props;
    const theme = useTheme();
    const linkOut = useApplicationLink(application.baseUrl);

    const pushRecentApplication = usePushRecentApplication();
    const [detailModalOpen, setDetailModalOpen] = useState(false);

    return (
        <>
            <ApplicationDetailDialog
                application={application}
                open={detailModalOpen}
                onClose={() => setDetailModalOpen(false)}
            />
            <Tooltip arrow title={getCardTooltipDescription(application)}>
                <ButtonBase
                    aria-label='application card'
                    focusRipple={true}
                    sx={{
                        width: {
                            mobile: '100%',
                            tablet_mg: '48%',
                            tablet_lg: '48%',
                            laptop: '30%',
                        },
                        borderRadius: '16px',
                    }}
                    onClick={() => {
                        if (getApplicationType(application) == ApplicationType.OnPremise) {
                            setDetailModalOpen(true);
                        } else {
                            pushRecentApplication(application);
                        }
                    }}
                    href={
                        getApplicationType(application) !== ApplicationType.OnPremise
                            ? linkOut()
                            : ''
                    }
                    target='_blank'
                >
                    <Paper
                        variant='outlined'
                        sx={{
                            width: '100%',
                            height: HEIGHT,
                            display: 'flex',
                            flexDirection: 'column',
                            border: `0.5px solid ${theme.palette.divider}`,
                            alignItems: 'start',
                            borderRadius: '16px',
                            backgroundColor: theme.palette.background.default,
                            ':hover': {
                                backgroundColor: theme.palette.action.hover,
                            },
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                margin: '16px',
                                overflow: 'hidden',
                            }}
                        >
                            <AppIcon
                                src={application.imageUrl}
                                appName={application.displayName || application.name}
                            />
                            <Typography
                                variant='h6'
                                fontWeight='600'
                                fontSize='14px'
                                marginTop='14px'
                            >
                                {application.displayName}
                            </Typography>
                            {application.shortDescription && (
                                <Typography
                                    variant='body2'
                                    textAlign='left'
                                    marginTop='2px'
                                    fontSize='14px'
                                    fontWeight='400'
                                    sx={{
                                        color: 'text.secondary',
                                    }}
                                >
                                    {textUtils.truncateText(
                                        application.shortDescription,
                                        DESCRIPTION_LIMIT,
                                    )}
                                </Typography>
                            )}
                        </div>
                    </Paper>
                </ButtonBase>
            </Tooltip>
        </>
    );
};

export default ApplicationCard;
