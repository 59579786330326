import { Components, PaletteMode, Switch, Theme, ThemeOptions, styled } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { MuiButtonTheme } from './mui-overrides/app-button-theme';
import { MuiTooltipTheme } from './mui-overrides/app-tooltip-theme';

const componentSettings = (mode: PaletteMode): Components<Omit<Theme, 'components'>> => ({
    MuiButton: MuiButtonTheme(),
    MuiTooltip: MuiTooltipTheme(mode),
});

// https://www.figma.com/design/gOZ3br4S6iMM39ksNaEa2j/%E2%AD%90%EF%B8%8F--Commerce%3A-Web-Components-(Beta)?node-id=3878-168165&t=WUWlXIcTNZeBi5gE-0\
const lightTheme: ThemeOptions['palette'] = {
    primary: {
        main: '#303030',
        dark: '#1A1A1A',
        light: '#828282',
        contrastText: '#FFFFFF',
    },
    background: {
        default: '#FAFAFA',
        paper: '#FFFFFF',
    },
    secondary: {
        main: '#5F249F',
        dark: '#42196F',
        light: '#B280FF',
        contrastText: '#FFFFFF',
    },
    error: {
        main: '#DD1708',
        dark: '#890802',
        light: '#FB9189',
        contrastText: '#FFFFFF',
    },
    warning: {
        main: '#AB5A05',
        dark: '#654700',
        light: '#F99932',
        contrastText: '#FFFFFF',
    },
    info: {
        main: '#086DD9',
        dark: '#134085',
        light: '#70B3FA',
        contrastText: '#FFFFFF',
    },
    success: {
        main: '#24802D',
        dark: '#064E05',
        light: '#3CC84A',
        contrastText: '#FFFFFF',
    },
    action: {
        active: 'rgba(0,0,0,0.54)',
        hover: 'rgba(0,0,0,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0,0,0,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0,0,0,0.26)',
        disabledBackground: 'rgba(0,0,0,0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(0,0,0,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
    divider: '#E0E0E0',
    text: {
        primary: '#212121',
        secondary: '#656565',
        disabled: '#9E9E9E',
    },
};

const darkTheme: ThemeOptions['palette'] = {
    primary: {
        main: '#EEEEEE',
        dark: '#FFFFFF',
        light: '#757575',
        contrastText: '#292929',
    },
    background: {
        default: '#171717',
        paper: '#1F1F1F',
    },
    secondary: {
        main: '#B280FF',
        dark: '#E3D1FF',
        light: '#5F259F',
        contrastText: '#292929',
    },
    error: {
        main: '#FF6459',
        dark: '#FFC9C7',
        light: '#BA1408',
        contrastText: '#292929',
    },
    warning: {
        main: '#DC8126',
        dark: '#FECE8F',
        light: '#874D12',
        contrastText: '#292929',
    },
    info: {
        main: '#379DEE',
        dark: '#B2DBFC',
        light: '#085DA0',
        contrastText: '#292929',
    },
    success: {
        main: '#59A94F',
        dark: '#A3E796',
        light: '#2E6827',
        contrastText: '#292929',
    },
    action: {
        active: 'rgba(255,255,255,0.64)',
        hover: 'rgba(255,255,255,0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.30)',
        disabledBackground: 'rgba(255,255,255,0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
    },
    divider: '#3C3C3C',
    text: {
        primary: '#F2F2F2',
        secondary: '#A3A4A6',
        disabled: '#6B696D',
    },
};

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: false;
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true;
        tablet_md: true;
        tablet_lg: true;
        laptop: true;
        desktop: true;
    }
}

export const breakpoints = {
    values: {
        mobile: 0,
        tablet_md: 599,
        tablet_lg: 904,
        laptop: 1239,
        desktop: 1439,
    },
};

const typography: TypographyOptions = {
    fontFamily: 'Inter, sans-serif',
};

export const getDesignTokens = (mode: PaletteMode) => ({
    components: componentSettings(mode),
    palette: {
        mode,
        ...(mode === 'light' ? lightTheme : darkTheme),
    },
    breakpoints,
    typography,
});

export const ThemeModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#f5910f',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

export const FdTheme = {
    light: 'light',
    dark: 'dark',
};

export const customColors = {
    sidebarActiveBgColor: {
        dark: '#EEEEEE29',
        light: '#30303014',
    },
    sidebarHoverBgColor: {
        dark: '#EEEEEE29',
        light: '#30303014',
    },
    tooltipBackground: {
        dark: '#979797',
        light: '#323232',
    },
};
