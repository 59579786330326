import { useTranslation } from 'react-i18next';
import CommonErrorPage from './CommonErrorPage';
import { useTheme } from '@mui/material';

const CouldNotLoadPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <CommonErrorPage
            imageUrl={`/images/page-could-not-load-${theme.palette.mode}.svg`}
            title={t('ERROR_PAGE.COULD_NOT_LOAD.TITLE')}
            subtitle={t('ERROR_PAGE.COULD_NOT_LOAD.SUBTITLE')}
            showReturnHomeButton
            showReloadButton
        />
    );
};

export default CouldNotLoadPage;
