import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useOrganizationContext } from '@ncr-voyix-commerce/react-common-components';
import shortcutService from 'services/shorcuts.service';
import { HomeUserApplicationContext } from 'contexts/HomeUserApplicationContext';
import EmptyContentCard from 'components/empty-content-card/EmptyContentCard';
import { ShortcutGroupModel } from 'models/ShortcutGroupModel';
import ShortcutGroupCard from './ShortcutGroupCard';

const featuredShortcutNames = [
    'manage-invoices',
    'run-common-reports',
    'manage-users',
    'manage-schedule',
];

const ShortcutListV2 = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { organization } = useOrganizationContext();
    const [shortcutGroups, setShortcutGroups] = useState<ShortcutGroupModel[]>([]);
    const { userApplications } = useContext(HomeUserApplicationContext);
    const [featuredShortcutGroups, setFeaturedShortcutGroups] = useState<ShortcutGroupModel[]>([]);

    useEffect(
        function getShortcutGroups() {
            if (organization?.organizationName && Boolean(userApplications.length)) {
                setLoading(true);
                shortcutService
                    .getShortcutGroups(organization.organizationName)
                    .then((response) => {
                        setShortcutGroups(
                            response.data.filter(
                                (item) => !featuredShortcutNames.includes(item.name),
                            ),
                        );
                        setFeaturedShortcutGroups(
                            response.data.filter((item) =>
                                featuredShortcutNames.includes(item.name),
                            ),
                        );
                    })
                    .catch(() => {})
                    .finally(() => setLoading(false));
            }
        },
        [organization?.organizationName, userApplications],
    );

    if (loading) {
        return <Skeleton sx={{ height: '170px' }} />;
    }

    if (shortcutGroups.length === 0 && featuredShortcutGroups.length === 0) {
        return (
            <EmptyContentCard
                iconBefore={
                    <img
                        src={'/images/no-shortcuts-icon-light.svg'}
                        alt='No shortcuts'
                        height={64}
                        width={64}
                    />
                }
                title={t('HOME.SHORTCUT_LIST.NO_SHORTCUTS.TITLE')}
                subtitle={t('HOME.SHORTCUT_LIST.NO_SHORTCUTS.SUBTITLE')}
            />
        );
    }

    if (featuredShortcutGroups.length > 0) {
        if (featuredShortcutGroups.length > 3) {
            setShortcutGroups((shortcutGroups) => [
                ...shortcutGroups,
                ...featuredShortcutGroups.slice(3),
            ]);
            setFeaturedShortcutGroups((featuredShortcutGroups) => [
                ...featuredShortcutGroups.slice(0, 3),
            ]);
        }

        return (
            <Stack gap={2} width='100%'>
                {!loading && (
                    <Stack direction='row' gap={2} flexWrap='wrap'>
                        {featuredShortcutGroups.map((featuredShortcutGroup) => (
                            <ShortcutGroupCard
                                isFeaturedShortcutGroup={true}
                                shortcutGroup={featuredShortcutGroup}
                                key={featuredShortcutGroup.name}
                            />
                        ))}
                    </Stack>
                )}
                {!loading && (
                    <Stack direction='row' gap={2} flexWrap='wrap'>
                        {shortcutGroups.map((shortcutGroup) => (
                            <ShortcutGroupCard
                                shortcutGroup={shortcutGroup}
                                key={shortcutGroup.name}
                            />
                        ))}
                    </Stack>
                )}
            </Stack>
        );
    }

    return (
        <Stack gap={2} width='100%'>
            {!loading && (
                <Stack direction='row' gap={2} flexWrap='wrap'>
                    {shortcutGroups.map((shortcutGroup) => (
                        <ShortcutGroupCard shortcutGroup={shortcutGroup} key={shortcutGroup.name} />
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default ShortcutListV2;
