import { Link } from '@mui/material';

const AppLink = (props: { href: string; label: string }) => {
    return (
        <Link
            href={props.href}
            target='_blank'
            underline='none'
            rel='noopener noreferrer'
            sx={(theme) => ({
                color: theme.palette.text.primary,
                transition: 'color .25s cubic-bezier(.215,.61,.355,1)',
                ':hover': {
                    color: theme.palette.primary.main,
                },
            })}
        >
            {props.label}
        </Link>
    );
};

export default AppLink;
