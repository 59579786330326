import { RccEnvironment } from '@ncr-voyix-commerce/react-common-components';

export const getEnvironment = (): RccEnvironment => {
    return window._env_.BSL_ENVIRONMENT!;
};

const applicationConfig = {
    name: 'HOME',
};

export default applicationConfig;
