import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useOrganizationContext, useRccContext } from '@ncr-voyix-commerce/react-common-components';
import { Navigate } from 'react-router-dom';
import client from 'services/client';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export enum UserTenantAuthorization {
    Unset,
    UnauthorizedNoOrganization,
    Unauthorized,
    Authorized,
}

export const isUnauthorized = (value: UserTenantAuthorization) => {
    return value === UserTenantAuthorization.Unauthorized;
};

export const isAuthorized = (value: UserTenantAuthorization) => {
    return value === UserTenantAuthorization.Authorized;
};

export const hasNoOrganization = (value: UserTenantAuthorization) => {
    return value === UserTenantAuthorization.UnauthorizedNoOrganization;
};

interface IUserContext {
    userTenantLoaded: boolean;
    setUserTenantLoaded: Dispatch<SetStateAction<boolean>>;
    userTenantAuthorized: UserTenantAuthorization;
    setUserTenantAuthorized: Dispatch<SetStateAction<UserTenantAuthorization>>;
}

export const UserTenantContext = createContext<IUserContext>({
    userTenantLoaded: false,
    setUserTenantLoaded: () => {},
    userTenantAuthorized: UserTenantAuthorization.Unset,
    setUserTenantAuthorized: () => {},
});

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
    const [userTenantAuthorized, setUserTenantAuthorized] = useState<UserTenantAuthorization>(
        UserTenantAuthorization.Unset,
    );
    const { organization, totalOrganizations, hasPreloaded, isLoading, error} =
        useOrganizationContext();
    const [userTenantLoaded, setUserTenantLoaded] = useState(false);
    const ldClient = useLDClient();
    const { userInfo } = useRccContext();

    useEffect(
        function setUserStatus() {
            setUserTenantLoaded(userTenantAuthorized !== UserTenantAuthorization.Unset);
        },
        [userTenantAuthorized, setUserTenantLoaded],
    );

    useEffect(
        function checkUserHasOrgAccess() {
            if ((!isLoading && hasPreloaded && totalOrganizations === 0) || error?.status === 403) {
                setUserTenantAuthorized(UserTenantAuthorization.UnauthorizedNoOrganization);
            }
        },
        [isLoading, hasPreloaded, totalOrganizations, error, organization?.organizationName],
    );

    useEffect(
        function setLDSession() {
            if (ldClient && userInfo && organization) {
                ldClient.identify({
                    key: userInfo.email,
                    custom: {
                        organization: organization.organizationName,
                    },
                });
            }
        },
        [ldClient, userInfo, organization],
    );

    useEffect(
        function setSessionUserTenant() {
            if (organization) {
                client.defaults.headers.common['nep-organization'] = organization.organizationName;
                setUserTenantAuthorized(UserTenantAuthorization.Authorized);
            } else if (!isLoading && hasPreloaded) {
                setUserTenantAuthorized(UserTenantAuthorization.UnauthorizedNoOrganization);
            }
        },
        [
            setUserTenantAuthorized,
            isLoading,
            organization,
            hasPreloaded,
        ],
    );
    const userTenantGlobalContext = useMemo(
        () => ({
            userTenantLoaded,
            setUserTenantLoaded,
            userTenantAuthorized,
            setUserTenantAuthorized,
        }),
        [userTenantLoaded, userTenantAuthorized],
    );

    if (organization && window.location.pathname === '/forbidden') {
        return <Navigate replace to="/home"/>
    }

    return (
        <UserTenantContext.Provider value={userTenantGlobalContext}>
            {children}
        </UserTenantContext.Provider>
    );
};
