import { LoginCallback } from '@okta/okta-react';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from 'pages/HomePage';
import Loading from 'components/loading/Loading';
import { ProtectedRoutes } from './ProtectedRoutes';
import NotFoundPage from 'components/error-boundary/views/NotFoundPage';
import AllApplicationsPage from 'pages/AllApplicationsPage/AllApplicationsPage';
import UnauthorizedPage from 'components/error-boundary/views/UnauthorizedPage';
import { AppAuthorizedGuard } from 'components/AppRouterGuards/AuthorizationGuard';

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<AppAuthorizedGuard />}>
                <Route path='/' element={<ProtectedRoutes />}>
                    <Route path='home' element={<HomePage />} />
                    <Route path='forbidden' element={<UnauthorizedPage />} />
                    <Route path='all-applications' element={<AllApplicationsPage />} />
                </Route>
                <Route
                    path='login/callback'
                    element={<LoginCallback loadingElement={<Loading />} />}
                />
                <Route path='*' element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
