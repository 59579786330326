import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import useMobileView from '../../hooks/useMobileView';

interface EmptyContentCardProps {
    iconBefore?: React.ReactNode;
    title: string;
    subtitle?: string;
    hasAction?: boolean;
    actionButtonText?: string;
    actionButtonIconBefore?: React.ReactNode;
    actionButtonOnClick?: () => void;
}

const EmptyContentCard = (props: EmptyContentCardProps) => {
    const {
        iconBefore,
        title,
        subtitle,
        hasAction,
        actionButtonText,
        actionButtonIconBefore,
        actionButtonOnClick,
    } = props;
    const isMobile = useMobileView();
    
    return (
        <Stack
            sx={{
                px: 2,
                py: isMobile ? 2 : 3,
                width: '100%',
                border: '1px solid',
                borderRadius: '16px',
                borderColor: (theme) => theme.palette.divider,
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {iconBefore}
            <Stack sx={{ alignItems: 'center' }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 600, textAlign: 'center' }}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography
                        variant='subtitle1'
                        sx={{ color: (theme) => theme.palette.text.secondary, textAlign: 'center' }}
                    >
                        {subtitle}
                    </Typography>
                )}
            </Stack>
            {hasAction && (
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: (theme) => theme.palette.primary.dark,
                    }}
                    startIcon={actionButtonIconBefore}
                    onClick={actionButtonOnClick}
                >
                    {actionButtonText}
                </Button>
            )}
        </Stack>
    );
};

export default EmptyContentCard;
