import React from 'react';
import {
    Box,
    Divider,
    IconButton,
    ListItem,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { ApplicationCategoryModel } from '../../models/ApplicationCategoryModel';
import UseTabletView from '../../hooks/useTabletView';
import { useSidenav } from '../../contexts/SidenavContext';

export const AppFilterMoreMenu = ({
    anchorRef,
    menuOpen,
    onMenuClose,
    categories,
    chipListLength,
    handleDropdownSelection,
    isCategorySelected,
    headerName,
    footerName,
}: {
    anchorRef: HTMLElement | null;
    menuOpen: boolean;
    onMenuClose: () => void;
    categories: ApplicationCategoryModel[];
    chipListLength: number;
    handleDropdownSelection: (category: ApplicationCategoryModel) => void;
    isCategorySelected: (category: ApplicationCategoryModel) => boolean;
    headerName: string;
    footerName: string;
}) => {
    const { t } = useTranslation();
    const defaultCategory = categories[0];
    const remainingCategories = categories.slice(chipListLength, categories.length);
    const theme = useTheme();
    const isTablet = UseTabletView();
    const {isSidenavOpen} = useSidenav();

    const enrichedCategoryList = (
        <>
            {remainingCategories.map((category) => (
                <MenuItem
                    key={category.id}
                    onClick={() => handleDropdownSelection(category)}
                    sx={{
                        justifyContent: 'space-between',
                        backgroundColor: 'inherit',
                    }}
                >
                    {category.categoryName}
                    {isCategorySelected(category) && <CheckIcon />}
                </MenuItem>
            ))}
            <Divider />
            <ListItem
                sx={{
                    padding: '12px 16px 8px 16px',
                    fontWeight: 500,
                    fontSize: theme.typography.body2.fontSize,
                    color: theme.palette.text.secondary,
                    '&.Mui-disabled': {
                        opacity: 1,
                    },
                }}
            >
                {t('HOME.APPLICATIONS.FILTER.MENU_SUBTEXT')}
            </ListItem>
            {categories.map((category) => (
                <MenuItem
                    key={category.id}
                    onClick={() => handleDropdownSelection(category)}
                    sx={{
                        justifyContent: 'space-between',
                        backgroundColor: 'inherit',
                    }}
                >
                    {category.categoryName}
                    {isCategorySelected(category) && <CheckIcon />}
                </MenuItem>
            ))}
        </>
    );

    return (
        <Menu
            anchorEl={anchorRef}
            open={menuOpen}
            onClose={onMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: isTablet && isSidenavOpen ? 'left' : 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: isTablet && isSidenavOpen ? 'left' : 'right',
            }}
            slotProps={{
                paper: {
                    style: {
                        width: '30ch',
                    },
                },
            }}
        >
            <ListItem
                sx={{
                    justifyContent: 'space-between',
                    position: 'sticky',
                }}
            >
                <Typography fontWeight='500' fontSize='16px'>
                    {headerName}
                </Typography>
                <IconButton size='medium' onClick={() => onMenuClose()}>
                    <CloseIcon />
                </IconButton>
            </ListItem>
            <Divider />
            <Box
                sx={{
                    maxHeight: 48 * 4.5,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    scrollbarWidth: 'none',
                }}
            >
                {enrichedCategoryList}
            </Box>
            <Divider />
            <MenuItem
                onClick={() => handleDropdownSelection(defaultCategory)}
                sx={{
                    justifyContent: 'space-between',
                    fontWeight: 'bold',
                    position: 'sticky',
                }}
            >
                <Typography fontWeight='500' fontSize='14px' marginTop='10px'>
                    {footerName}
                </Typography>
            </MenuItem>
        </Menu>
    );
};
