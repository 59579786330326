import { ApplicationCategoryModel } from '../../models/ApplicationCategoryModel';
import {
    Box,
    Button,
    Divider,
    List,
    ListItemButton,
    SwipeableDrawer,
    Typography,
    useTheme,
} from '@mui/material';
import React, { Fragment } from 'react';
import CheckIcon from '@mui/icons-material/Check';

export const AppFilterMoreDrawer = ({
    menuOpen,
    onMenuClose,
    categories,
    onMenuOpen,
    handleDropdownSelection,
    isCategorySelected,
    headerName,
    footerName,
}: {
    menuOpen: boolean;
    onMenuClose: () => void;
    onMenuOpen: () => void;
    categories: ApplicationCategoryModel[];
    handleDropdownSelection: (category: ApplicationCategoryModel) => void;
    isCategorySelected: (category: ApplicationCategoryModel) => boolean;
    headerName: string;
    footerName: string;
}) => {
    const defaultCategory = categories[0];
    const theme = useTheme();

    return (
        <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor='bottom'
            open={menuOpen}
            onClose={onMenuClose}
            onOpen={onMenuOpen}
            PaperProps={{
                sx: {
                    borderTopLeftRadius: '16px',
                    borderTopRightRadius: '16px',
                },
            }}
        >
            <Box height={'auto'} width={'100%'}>
                <Box
                    sx={{
                        margin: '10px auto',
                        width: '36px',
                        height: '5px',
                        backgroundColor: theme.palette.action.active,
                        borderRadius: '33px',
                    }}
                />
                <Box
                    sx={{
                        padding: '0px 8px',
                    }}
                >
                    <Fragment>
                        <Box
                            sx={{
                                padding: '12px 8px',
                            }}
                            display='flex'
                            alignItems='center'
                        >
                            <Typography fontWeight='600' fontSize='14px'>
                                {headerName}
                            </Typography>
                        </Box>
                    </Fragment>
                </Box>
            </Box>
            <List >
                {categories.map((category: ApplicationCategoryModel) => (
                    <ListItemButton
                        onClick={() => {
                            handleDropdownSelection(category);
                        }}
                        sx={{
                            padding: '10px 16px',
                            justifyContent: 'space-between',
                        }}
                        selected={isCategorySelected(category)}
                        key={category.id}
                    >
                        <Typography variant='body1' textAlign='center'>
                            {category.categoryName}
                        </Typography>
                        {isCategorySelected(category) && <CheckIcon />}
                    </ListItemButton>
                ))}
            </List>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '12px 16px',
                }}
            >
                <Button
                    variant='outlined'
                    onClick={() => handleDropdownSelection(defaultCategory)}
                    sx={{
                        width: '80ch',
                    }}
                >
                    <Typography fontWeight='500' fontSize='14px'>
                        {footerName}
                    </Typography>
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};
