import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ApplicationList from 'components/application-list/ApplicationList';

const AllApplicationsPage = () => {
    const { t } = useTranslation();

    return (
        <Stack sx={{ gap: 2 }}>
            <Typography variant='h5' fontWeight='600'>
                {t('APPLICATIONS_PAGE.TITLE')}
            </Typography>
            <ApplicationList />
        </Stack>
    );
};

export default AllApplicationsPage;
