import axios from 'axios';
import { AccessToken, OktaAuth } from '@okta/okta-auth-js';
import { oktaAuthOptions } from 'constants/okta-auth-config';

const oktaAuth = new OktaAuth(oktaAuthOptions);

const client = axios.create({
    withCredentials: true,
});

client.interceptors.request.use(async (r) => {
    const tokenData = (await oktaAuth.tokenManager.get('accessToken')) as AccessToken;

    if (await oktaAuth.isAuthenticated()) {
        r.headers.Authorization = `AccessToken ${tokenData.accessToken}`;
    }

    return r;
});

export default client;
