import {
    Box, Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    SxProps, Toolbar,
    useTheme,
} from '@mui/material';
import { SidebarMenu } from './SidebarMenu';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { customColors } from 'theme/theme-util';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import useMobileView from 'hooks/useMobileView';
import { ApplicationSwitcher } from '@ncr-voyix-commerce/react-common-components';
import { useSidenav } from '../../contexts/SidenavContext';
import UseLaptopView from '../../hooks/useLaptopView';
import PoweredByNcrIcon from '../../components/PoweredByNcrIcon/PoweredByNcrIcon';

const Sidebar = ({ sideBarMenuItems }: { sideBarMenuItems: SidebarMenu[] }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMobileView();
    const isLaptop = UseLaptopView();
    const { toggleSidenav } = useSidenav();

    const sharedMenuItemProps: SxProps = {
        borderRadius: 2,
        py: 1,
        pl: '30px',
    };

    const activeMenuItemProps: SxProps = {
        backgroundColor: customColors.sidebarActiveBgColor[theme.palette.mode],
        color:
            theme.palette.mode === 'light'
                ? theme.palette.primary.dark
                : theme.palette.primary.main,
        ':hover': {
            backgroundColor: customColors.sidebarActiveBgColor[theme.palette.mode],
        },
        ...sharedMenuItemProps,
    };

    const defaultMenuItemProps: SxProps = {
        color: theme.palette.text.secondary,
        ...sharedMenuItemProps,
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: (theme) => theme.palette.background.default,
            }}
        >
            <Grid container flexDirection='row' sx={{ py: '12px', px: '18px' }} justifyContent='space-between'>
                    <ApplicationSwitcher />
                    {isMobile && (
                            <IconButton onClick={toggleSidenav} aria-label='close drawer'>
                                <CloseIcon sx={{ width: 24, height: 24 }} />
                            </IconButton>
                    )}
            </Grid>
            <List sx={{ px: 1, py: 0, flexGrow: 1 }}>
                {sideBarMenuItems.map((menuItem) => (
                    <ListItem disablePadding sx={{ py: 0.5 }} key={menuItem.text}>
                        {menuItem.showMenuOption && (
                            <ListItemButton
                                component={Link}
                                to={menuItem.path}
                                onClick={!isLaptop ? toggleSidenav : () => {}}
                                sx={
                                    menuItem.isMenuActive(location.pathname)
                                        ? activeMenuItemProps
                                        : defaultMenuItemProps
                                }
                            >
                                <ListItemText
                                    primary={t(menuItem.text)}
                                    primaryTypographyProps={{
                                        sx: {
                                            fontWeight: menuItem.isMenuActive(location.pathname)
                                                ? 600
                                                : 500,
                                            fontSize: 14,
                                            fontFamily: 'Inter, sans-serif',
                                        },
                                    }}
                                />
                            </ListItemButton>
                        )}
                    </ListItem>
                ))}
            </List>
            <Divider />
            <Toolbar
                sx={{
                    ...(!(isMobile || !isLaptop) && {
                        height: 'var(--app-footer-height)',
                    }),
                    justifyContent: 'center',
                }}
            >
                <PoweredByNcrIcon />
            </Toolbar>
        </Box>
    );
};

export default Sidebar;
