import { Component, ComponentType, createElement, ErrorInfo, PropsWithChildren } from 'react';

type ErrorBoundaryState = {
    error: any;
};

type ErrorBoundaryProps = PropsWithChildren<{
    fallback: ComponentType<FallbackProps>;
}>;

type FallbackProps = {
    error: any;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        this.setState({
            error: event.reason,
        });
    };

    private errorHandler = (event: ErrorEvent) => {
        this.setState({
            error: event.error,
        });
    };

    public state: ErrorBoundaryState = {
        error: null,
    };

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error: error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
        window.addEventListener('error', this.errorHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
        window.removeEventListener('error', this.errorHandler);
    }

    public render() {
        if (this.state.error) {
            const error = this.state.error;
            return createElement(this.props.fallback, { error: error });
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
