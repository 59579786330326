import { useTranslation } from 'react-i18next';
import CommonErrorPage from './CommonErrorPage';
import { useTheme } from '@mui/material';
import { hasNoOrganization, UserTenantContext } from 'contexts/UserTenantContext';
import { useContext } from 'react';

const UnauthorizedPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { userTenantAuthorized } = useContext(UserTenantContext);
    
    return <CommonErrorPage
            imageUrl={`/images/unauthorized-${theme.palette.mode}.svg`}
            title={t('ERROR_PAGE.403.TITLE')}
            subtitle={t('ERROR_PAGE.403.SUBTITLE')}
            showReturnHomeButton={!hasNoOrganization(userTenantAuthorized)}
        />;
};

export default UnauthorizedPage;
